<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    <h3 class="title-secondary">{{ getMyLanguage("profile", "profile.title") }}</h3>

                    <div class="box-error" v-if="errorsGet.dataProfile != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataProfile}}
                        </div>
                    </div>

                    <div class="boxes-seller" v-if="!errorsGet.dataProfile">
                        <div class="box box-seller">
                            <h3>{{ getMyLanguage("profile","profile.subtitle.my-profile") }}</h3>
                            <label>
                                {{ getMyLanguage("profile", "profile.label.agentType") }}
                                <div class="form form-disabled"><span>{{ getMyLanguage("status", dataProfile.agentType) }}</span></div>
                            </label>
                            
                            <label>
                                {{ getMyLanguage("profile", "profile.label.name") }}
                                <div class="form form-disabled"><span>{{dataProfile.name}}</span></div>
                            </label>
                            
                            <label>
                                {{ getMyLanguage("profile", "profile.label.email") }}
                                <div class="form form-disabled"><span>{{dataProfile.email}}</span></div>
                            </label>

                            <label>
                                {{ getMyLanguage("profile", "profile.label.phone") }}
                                <div class="form form-disabled"><span>{{dataProfile.phoneNumber}}</span></div>
                            </label>
                        </div>

                        
                        <div class="box box-parent-seller">
                            <h3>{{ getMyLanguage("profile","profile.subtitle.agent-profile") }}</h3>
                            <label>
                                {{ getMyLanguage("profile", "profile.label.parentAgentType") }}
                                <div class="form form-disabled"><span>{{ getMyLanguage("status", dataProfile.parentAgentType) }}</span></div>
                            </label>
                            
                            <label>
                                {{ getMyLanguage("profile", "profile.label.name") }}
                                <div class="form form-disabled"><span>{{dataProfile.parentName}}</span></div>
                            </label>

                            <label>
                                {{ getMyLanguage("profile", "profile.label.email") }}
                                <div class="form form-disabled"><span>{{dataProfile.parentEmail}}</span></div>
                            </label>

                            <label>
                                {{ getMyLanguage("profile", "profile.label.phone") }}
                                <div class="form form-disabled"><span>{{dataProfile.parentPhoneNumber}}</span></div>
                            </label>
                        </div>
                    </div>
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/api.js';

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
import { error } from 'highcharts';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                dataProfile: [],

                errorsGet: {
                    dataProfile: false
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },


        async mounted () {
            this.flagUrl();

            api.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataProfile = response.data
            })
            .catch(error => {
                this.errorsGet.dataProfile = error.response.status
            })
        }
    }
</script>


<style scoped>

    .box h3{font-size: 22px; color: var(--color-text-secondary); margin-bottom: 30px;}
    .box h3:after{content: ''; display: block; width: 60px; height: 3px; background: var(--color-quaternary); margin-top: 3px;}

    @media (min-width: 768px){
        .boxes-seller{display: flex; justify-content: space-between;}
        .boxes-seller .box{flex: 0 0 48%; max-width: 48%;}
    }
    
</style>