<template>
    <ion-app :scroll-events="true">
        <div class="sign-register">
            <div class="content-login">
                <div class="cont">
                    <video id="dneVideo" class="bg-video" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
                        <source src="@/assets/images/content/dne-token.mp4" type="video/mp4">
                        <source src="@/assets/images/content/dne-token.webm" type="video/webm">
                    </video>

                    <div class="cont-login">
                        <div class="form-login">
                            <form>
                                <div class="header-login">
                                    <img src="@/assets/images/dne-sales.svg" >
                                    
                                    <SelectLanguage />
                                </div>

                                <div class="login-fields" v-if="!stepLogin.load">
                                    <!-- EMAIL LOGIN -->
                                    <input v-model="sign.username" type="email" class="form form-email" @keyup.enter="validateForm()" :class='{error: sign.emptyUsername}' spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("login", "login.placeholder.email")'>

                                    <!-- PASSWORD -->
                                    <div class="enterPass">
                                        <input v-model="sign.password" type="password" v-if="!showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>
                                        <input v-model="sign.password" type="text" v-if="showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>

                                        <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                        <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                    </div>

                                    <div class="btn-primary btn-disable" v-if="sign.username.length <= 5 || sign.password.length <= 7">{{ getMyLanguage("login","login.btn.continue") }}</div>
                                    <div @click="validateForm()" expand="block" class="btn-primary" v-if="stepLogin.load === false && sign.username.length > 5 && sign.password.length > 7">{{ getMyLanguage("login", "login.btn.continue") }}</div>
                                    <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                                </div>

                                <div class="load-login" v-if="stepLogin.load"></div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ion-app>

</template>

<script>
    import {IonApp, toastController} from '@ionic/vue';

    import SelectLanguage from '@/components/SelectLanguage.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/api.js'

    export default {
        components: {
            IonApp,
            SelectLanguage
        },
        data () {
            return {
                flagSelected: {},
                stepLogin: {
                    'load': false,
                    'loadCode': false,
                    'statusEmailPass': true,
                    'statusCode': false
                },
                sign: {
                    "username": "",
                    "password": ""
                },
                showPass: false,
                codeConfirm: {}
            }
        },
        methods: {
            async openToast() {
                const toast = await toastController
                    .create({
                    message: 'E-mail ou senha inválida',
                    color: "danger",
                    duration: 2000
                    })
                return toast.present();
            },

            
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            createAccount(){
                window.location.href = this.urlCreateAccount
            },

            referrerId(){
                const idRef = window.location.href.split("referrer=");

                if ( idRef[1] != undefined ){
                    this.urlCreateAccount = '/' + this.flagSelected + '/register/?referrer=' + idRef[1]
                } else{
                    this.urlCreateAccount = '/' + this.flagSelected + '/register'
                }
            },

            rememberPass(){
                window.navigator.vibrate(200);
            },

            resendCode(){
                window.navigator.vibrate(200);
            },

            validateForm(){
                this.stepLogin.load = true

                if ( this.sign.username == undefined || this.sign.username == "" ){
                    this.sign.emptyUsername = true
                    this.stepLogin.load = false
                } else if ( this.sign.password == undefined || this.sign.password == "" ){
                    this.sign.emptyUsername = false
                    this.sign.emptyPassword = true
                    this.stepLogin.load = false
                } else{
                    this.sign.emptyUsername = false
                    this.sign.emptyPassword = false
                    
                    this.loginPlatform()
                }
            },

            validateCode(){
                
                if ( this.codeConfirm._2fa == undefined || this.codeConfirm._2fa == "" ){
                    this.codeConfirm.empty2fa = true
                } else{
                    this.stepLogin.loadCode = true
                }
            },

            loginPlatform(){
                const loginPlatform = this.sign;

                api.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    this.stepLogin.load = true
                    
                    localStorage.setItem('accessToken',response.data.accessToken);
                    localStorage.setItem('codeLanguage',response.data.profile.account_language_alpha_2);
                    localStorage.setItem('flagLanguage',response.data.profile.account_language_flag);

                    this.flagSelected = localStorage.getItem('codeLanguage')
                    window.location.href =  this.flagSelected + '/dashboard/'
                })
                .catch(error => {
                    this.stepLogin.load = false
                    console.log(error.message);
                    console.clear()

                    this.openToast();
                })
            },


            loginCode(){
                const loginCode = this.codeConfirm;

                api.post("/api/v1/platform/account/login", loginCode)
                .then(response => {
                    this.registerId = response.data.id;
                    window.location.href =  this.flagSelected + '/home/' + response.data.id
                })
                .catch(error => {
                    this.errorMessage = error.message;
                })
            }
        },


        async mounted () {
            this.flagUrl();

            setTimeout(() => {
                this.referrerId()
            }, 1000);
        }
    }
</script>


<style scoped>
    .selectLang{position: initial;}

    .sign-register{background: var(--color-primary); height: 100%;}

    .content-login{display: flex; align-items: center; width: 100%; height: 100%;}
    .content-login .cont{width: 100%; height: 100%; overflow: hidden;}
    .content-login .cont .cont-login{position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); width: 90%;}

    .bg-video{display: block; width: auto; height: auto;}
    @media (max-width: 992px){
        .bg-video{height: 100%;}
    }
    
    @media (min-width: 992px){
        .bg-video{min-width: 100%; min-height: 100%; height: auto;}
    }


    /* .form-login{ background: url('~@/assets/images/bgs/bg-mobile.jpg') no-repeat; background-size: 100% auto; max-width: 600px;} */
    @media (max-width: 576px){
        .form-login{border-radius: 20px; padding: 20px !important;}
    }

    @media (min-width: 576px){
        .form-login{margin: 0 auto 20px; border-radius: 20px; padding: 30px !important;}
    }

    .header-login{display: flex; align-items: center; justify-content: space-between; margin-bottom: 30px; position: relative; z-index: 30;}
    .header-login img{width: auto; height: 50px;}

    .login-fields{position: relative; -webkit-animation: showLogin .4s alternate;}

    .enterPass{margin: 15px 0;}
    .enterPass .eyePass{top: 50%; transform: translateY(-50%);}

    .load-login{background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: auto 60%; margin: 0 auto;}
    @media (max-width: 576px){
        .load-login{width: 170px; height: 170px;}
    }
    @media (min-width: 576px){
        .load-login{width: 188px; height: 188px;}
    }

    .form{height: 50px; line-height: 50px; margin-bottom: 0;}
    @media (min-width: 576px){
        .form{height: 54px; line-height: 54px;}
    }

    .form.error{border: 1px solid var(--color-cancel);}

    .btn-primary{width: 100%;}

    @keyframes showLogin{
        0% {top: 10px;}
        100% {top: 0;}
    }
</style>