<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    
                    <h3 class="title-secondary">{{getMyLanguage("errors", "error.404")}}</h3>
                    
                    <div class="erro404">
                        <img src="~@/assets/images/icons/404.svg" alt="Page not found">
                    </div>                    
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
            }
        },
        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },


        async mounted () {
            this.flagUrl();
        }
    }
</script>


<style scoped>
    .erro404{display: flex; justify-content: center; width: 100%;}
    .erro404 img{width: 80%; max-width: 400px;}
</style>