import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import Leads from '@/pages/Leads/Leads.vue'
import Success from '@/pages/Leads/Success.vue'
import Agents from '@/pages/Leads/Agents.vue'
import Sales from '@/pages/Sales/Sales.vue'
import Sales2 from '@/pages/Sales/Sales.vue'
import SalesForce from '@/pages/Sales/SalesForce.vue'
import SalesForce2 from '@/pages/Sales/SalesForce.vue'

import Invites from '@/pages/Invites/Invites.vue'
import Invite from '@/pages/Activity/Invite.vue'
import InviteContract from '@/pages/Activity/InviteContract.vue'
import InviteCompany from '@/pages/Activity/InviteCompany.vue'
import InviteContractCompany from '@/pages/Activity/InviteContractCompany.vue'
import InviteSuccess from '@/pages/Activity/ActivateSuccess.vue'

import NotFound from '@/pages/NotFound/NotFound.vue'
import Page403 from '@/pages/NotFound/Page403.vue'

import Login from '@/pages/Seller/Login.vue'
import Logged from '@/pages/Seller/Logged.vue'
import Profile from '@/pages/Seller/Profile.vue'
import Settings from '@/pages/Seller/Settings.vue'
import Manuals from '@/pages/Seller/Manuals.vue'
import Report from '@/pages/Seller/Report.vue'

import Dashboard from '@/pages/Dashboard/Dash.vue'
import DashAgent from '@/pages/Dashboard/Dash.vue'
import AgentSign from '@/pages/Dashboard/AgentSign.vue'


const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/en/login/' },
  { path: '/ar', redirect: '/ar/dashboard' },
  { path: '/en', redirect: '/en/dashboard' },
  { path: '/es', redirect: '/es/dashboard' },
  { path: '/pt', redirect: '/pt/dashboard' },
  { path: '/ru', redirect: '/ru/dashboard' },
  
  { path: '/:lang/leads/', name: 'Leads', component: Leads },
  { path: '/:lang/leads/sales', name: 'Sales2', component: Sales2 },
  { path: '/:lang/leads/success', name: 'Success', component: Success },
  { path: '/:lang/leads/agents', name: 'Agents', component: Agents },
  { path: '/:lang/leads/sales-force', name: 'SalesForce2', component: SalesForce2 },

  
  { path: '/:lang/sales', name: 'Sales', component: Sales },
  { path: '/:lang/sales/sales-force', name: 'SalesForce', component: SalesForce },
  
  { path: '/:lang/invites/', name: 'Invites', component: Invites },
  { path: '/:lang/agent/invite/:registerId', name: 'Invite', component: Invite },
  { path: '/:lang/agent/invite-contract/:registerId', name: 'InviteContract', component: InviteContract },

  { path: '/:lang/company/invite/:registerId', name: 'InviteCompany', component: InviteCompany },
  { path: '/:lang/company/invite-contract/:registerId', name: 'InviteContractCompany', component: InviteContractCompany },

  { path: '/:lang/agent/invite/success', name: 'InviteSuccess', component: InviteSuccess },
  
  { path: '/:lang/login', name: 'Login', component: Login },
  { path: '/:lang/logged', name: 'Logged', component: Logged },
  { path: '/:lang/seller/profile/', name: 'Profile', component: Profile },
  { path: '/:lang/seller/settings/', name: 'Settings', component: Settings },
  { path: '/:lang/seller/manuals/', name: 'Manuals', component: Manuals },
  { path: '/:lang/seller/report/', name: 'Report', component: Report },

  { path: '/:lang/dashboard/', name: 'Dashboard', component: Dashboard },
  { path: '/:lang/dashboard/:registerId', name: 'DashAgent', component: DashAgent },
  { path: '/:lang/dashboard/agent-sign', name: 'AgentSign', component: AgentSign },
  
  { path: '/:lang/page403', name: 'Page403', component: Page403 },

  { path: '/:lang/:catchAll(.*)', component: NotFound },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
