<template>
    <ion-footer class="footer">
        <ion-row v-if="showContent">
            <div class="linkFooter">
                <div @click="lnkDash()" class="lnk-footer">
                    <img src="@/assets/images/menu/lnk-dash-footer.svg">
                </div>
            </div>

            <div class="linkFooter">
                <div @click="lnkClients()" class="lnk-footer">
                    <img src="@/assets/images/menu/lnk-clients-footer.svg">
                </div>
            </div>

            <div class="linkFooter" v-if='dataAgent.agentTypeCode === "regional_director"'>
                <div @click="lnkSalesForce()" class="lnk-footer">
                    <img src="@/assets/images/menu/lnk-sales-force-footer.svg">
                </div>
            </div>
            
            <div class="linkFooter">
                <div @click="lnkSales()" class="lnk-footer">
                    <img src="@/assets/images/menu/lnk-sales-footer.svg">
                </div>
            </div>

            <div class="linkFooter" v-if='dataAgent.agentTypeCode === "regional_director"'>
                <div class="lnk-footer" @click="lnkAgents()">
                    <img src="@/assets/images/menu/lnk-settings-footer.svg">
                </div>
            </div>
        </ion-row>

        <div id="overlayMenu"></div>
    </ion-footer>
</template>

<script>
    import {IonFooter, IonRow} from '@ionic/vue';

    import api from '@/config/api.js';

    export default {
        components: {
            IonFooter, IonRow,
        },
        data () {
            return {
                showContent: false,
                flagSelected: {},
                dataAgent: []
            }
        },

        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },

            lnkDash(){
                window.location.href = this.flagSelected + '/dashboard/'
            },

            lnkProfile(){
                window.location.href = this.flagSelected + '/seller/profile/'
            },

            lnkClients(){
                window.location.href = this.flagSelected + '/leads/'
            },

            lnkSales(){
                window.location.href = this.flagSelected + '/sales/'
            },

            lnkSalesForce(){
                window.location.href = this.flagSelected + '/sales/sales-force/'
            },

            lnkAgents(){
                window.location.href = this.flagSelected + '/leads/agents/'
            },

            // lnkNewLead(){
            //     window.location.href = this.flagSelected + '/leads/check/'
            // },

            lnkSettings(){
                window.location.href = this.flagSelected + '/dashboard'
                // window.location.href = this.flagSelected + '/seller/settings'
            },

            logoutPlatform(){
                // window.location.href = this.flagSelected + '/login/'
            }
        },

        async mounted () {
            this.flagUrl();

            await api.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataAgent = response.data
            })
            .catch(error => {
                
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
            })

            this.showContent = true
        }
    }
</script>


<style scoped>
    .footer{position: initial; display: none !important;}
    .footer ion-row{position: relative; z-index: 30; display: flex; justify-content: center; padding: 10px 16px; border-radius: 12px 12px 0 0; background: url('../assets/images/bg-green.png') no-repeat top center; background-size: 100% auto; -webkit-box-shadow: 0px 0 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 0 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 0 20px 0px rgba(0,0,0,0.1);}

    .linkFooter{width: calc(100%/5); display: flex; justify-content: center;}

    .lnk-footer{display: flex; justify-content: center; align-items: center; width: 30px; height: 30px; border: 2px solid rgba(255,255,255,0.4); border-radius: 50%; cursor: pointer;}
    .lnk-footer img{display: block; width: 16px; height: 16px;}


    @media (min-width: 576px){
        .linkFooter{width: auto; margin: 0 30px;}
    }
</style>