<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    
                    <h3 class="title-secondary">{{getMyLanguage("errors", "error-403.title")}}</h3>
                    
                    <div class="erro403">
                        <img src="~@/assets/images/icons/403.svg" alt="Error 403">
                        <div class="box msg-error">
                            <p>{{getMyLanguage("errors","error-403.msg")}}</p>
                        </div>
                    </div>

                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
            }
        },
        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },


        async mounted () {
            this.flagUrl();
        }
    }
</script>


<style scoped>
    .erro403{display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; margin: 0 auto; max-width: 400px;}
    .erro403 img{margin-bottom: 50px;}
    .erro403 .msg-error{width: 100%; text-align: center;}
    .erro403 .msg-error p{margin: 0;}
</style>