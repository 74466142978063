<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    
                    
                    <!-- CARDS -->
                    <h3 class="title-secondary">{{getMyLanguage("sales","sales-force.title")}}</h3>
                    
                    <div class="box-error" v-if="errorsGet.dataDash != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataDash}}
                        </div>
                    </div>
                    
                    <div class="load-green" v-if="!showContent"><span></span></div>

                    <div class="cardsDash" v-if="showContent && !errorsGet.dataDash">

                        <!-- SALES WINS -->
                        <div class="group-cards group-wins">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-wins")}}</div>

                            <div class="cards">
                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                            <h4>{{dataDash.wins.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                            <h4>{{dataDash.wins.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                            <h4>{{dataDash.wins.packages}} / {{dataDash.totalPackages}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.leads")}}</p>
                                            <h4>{{dataDash.wins.leads}} / {{dataDash.totalLeads}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-average-ticket.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions")}}</p>
                                            <h4>{{dataDash.wins.conversions}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-amount-sales.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.average-sale")}}</p>
                                            <h4>{{dataDash.wins.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions-ratio")}}</p>
                                            <h4>{{dataDash.wins.conversionsRatio.toLocaleString("pt-PT", {style:"percent", maximumFractionDigits: 2})}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- SALES PROGRESS -->
                        <div class="group-cards group-progress">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-in-progress")}}</div>

                            <div class="cards">
                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                            <h4>{{dataDash.draw.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                            <h4>{{dataDash.draw.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                            <h4>{{dataDash.draw.packages}} / {{dataDash.totalPackages}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.leads")}}</p>
                                            <h4>{{dataDash.draw.leads}} / {{dataDash.totalLeads}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-average-ticket.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions")}}</p>
                                            <h4>{{dataDash.draw.conversions}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-amount-sales.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.average-sale")}}</p>
                                            <h4>{{dataDash.draw.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions-ratio")}}</p>
                                            <h4>{{dataDash.draw.conversionsRatio.toLocaleString("pt-PT", {style:"percent", maximumFractionDigits: 2})}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- SALES LOSS -->
                        <div class="group-cards group-loss">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-loss")}}</div>

                            <div class="cards">
                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                            <h4>{{dataDash.loss.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                            <h4>{{dataDash.loss.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                            <h4>{{dataDash.loss.packages}} / {{dataDash.totalPackages}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.leads")}}</p>
                                            <h4>{{dataDash.loss.leads}} / {{dataDash.totalLeads}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-average-ticket.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions")}}</p>
                                            <h4>{{dataDash.loss.conversions}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-amount-sales.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.average-sale")}}</p>
                                            <h4>{{dataDash.loss.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions-ratio")}}</p>
                                            <h4>{{dataDash.loss.conversionsRatio.toLocaleString("pt-PT", {style:"percent", maximumFractionDigits: 2})}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- COMMISSIONS -->
                        <div class="group-cards group-commissions">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.commissions")}}</div>

                            <div class="cards">
                                <div class="box card">
                                    <div class="card-content total-withdraw">
                                        <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-leads")}}</p>
                                            <h4>{{dataDash.totalLeads}}</h4>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-withdraw.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.available")}}</p>
                                            <h4>{{dataDash.commissions.available.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-waiting.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.waiting-payment")}}</p>
                                            <h4>{{dataDash.commissions.waitingPayment.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.waiting-confirmation")}}</p>
                                            <h4>{{dataDash.commissions.waitingConfirmation.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-received.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.received")}}</p>
                                            <h4>{{dataDash.commissions.received.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-pending-approval.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.pending-approval")}}</p>
                                            <h4>{{dataDash.commissions.pendingApproval.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content">
                                        <img src="~@/assets/images/icons/dash-card-cancelled.svg" class="icon">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.cancelled")}}</p>
                                            <h4>{{dataDash.commissions.cancelled.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- LIST AGENTS -->
                    <div class="list-agents" v-if="showContent && !errorsGet.dataDash">
                        <h3 class="title-secondary" v-if="this.lengthAgent != 'load' && filter">{{getMyLanguage("sales","sales-force.title.by-agents")}}</h3>
                        
                        <div class="load load-center" v-if="this.lengthAgent === 'load'"></div>
                        
                        <div class="search-bar" v-if="dataAgents.length > 0 &&  filter">
                            <input type="text" class="form form-search" id="searchAgent" v-on:keyup="filterList" :placeholder='getMyLanguage("agents","agents.search")'>
                            <div class="btn-filter" @click="filter = true"></div>
                            <!-- <div class="btn-add" @click="newInviteAgent()">{{ getMyLanguage("agents", "agents.add") }}</div> -->
                        </div>

                        <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                        <div class="filter box" v-if="filter">
                            <div class="close-filter" @click="filter = false"></div>
                        </div>


                        <div class="box" v-if="lengthAgent">
                            <div class="scroll-horizontal">
                                <table class="list-result">
                                    <thead class="purchase-history-header">
                                        <tr>
                                            <th class="fullName">{{ getMyLanguage("list-result","name") }}</th>
                                            <th class="">{{ getMyLanguage("list-result","stage") }}</th>
                                            <th class="">{{ getMyLanguage("list-result","total-sold") }}</th>
                                            <th class="">{{ getMyLanguage("list-result","commission") }}</th>
                                            <th class="">{{ getMyLanguage("list-result","packages") }}</th>
                                            <th class="">{{ getMyLanguage("list-result","leads") }}</th>
                                            <th class="last-tablet">{{ getMyLanguage("list-result","conversions") }}</th>
                                            <th class="hide-tablet">{{ getMyLanguage("list-result","average-sale") }}</th>
                                            <th class="hide-tablet last">{{ getMyLanguage("list-result","ratio") }}</th>
                                        </tr>
                                    </thead>

                                    <tbody id="drop-list">
                                        <tr v-for="(agentsList,index) in dataAgents" :key="index" @click="lnkDash(agentsList.agentId)" class="list" :class="agentsList.stage">
                                            <td class="fullName"><span>{{agentsList.agentName}}</span></td>
                                            <td class="text-right stage"><span>{{agentsList.stage}}</span></td>
                                            <td class="text-right"><span>{{agentsList.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                            <td class="text-right"><span>{{agentsList.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                            <td class="text-right"><span>{{agentsList.packages}}</span></td>
                                            <td class="text-right"><span>{{agentsList.leads}}</span></td>
                                            <td class="text-right last-tablet"><span>{{agentsList.conversions}}</span></td>
                                            <td class="text-right hide-tablet"><span>{{agentsList.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                            <td class="text-right hide-tablet last"><span>{{agentsList.conversionsRatio.toLocaleString("pt-PT", {style: "percent"})}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="no-results" v-if="!lengthAgent">
                            <p>{{ getMyLanguage("agents", "no-agents") }}</p>
                        </div>
                    </div>


                    <!-- Chart -->
                    <!-- <div class="box Chart" v-if="showContent">
                        <Chart :options="dataChart" />
                    </div> -->
                    
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import api from '@/config/api.js'
    import moment from 'moment';

    // import { Chart } from 'highcharts-vue'

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer,
            // Chart
        },
        data () {
            return {
                flagSelected: {},
                lengthAgent: 'load',
                filter: false,
                dataAgents: [],
                showContent: false,
                dataDash: {},

                errorsGet: {
                    dataDash: false
                },

                // dataChart: {}
            }
        },
        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).format('DD/MM');
            },
            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchAgent");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            lnkDash(id){
                window.location.href = this.flagSelected + '/dashboard/' + id
            },
        },


        async mounted () {
            this.flagUrl();

            api.get('/api/v1/reports/commissions/agents/director')
            .then(response => {
                this.dataDash = response.data
                this.dataAgents = this.dataDash.agents
                
                 if ( this.dataAgents.length > 0 ){
                    this.lengthAgent = true
                } else{
                    this.lengthAgent = false
                }
            })
            .catch(error => {
                this.errorsGet.dataDash = error.response.status
            })

            setTimeout(() => {
                this.showContent = true
            }, 500);

            // const resumeClients = await api.get('/api/v1/agent/agents')
            // this.dataAgents = resumeClients.data
            
            // const resumeChart = await api.get('/api/v1/reports/commissions/agent/weekly')
            // this.dataChart = resumeChart.data
        }
    }
</script>


<style scoped>
    .title-secondary{margin-bottom: 20px;
    }
    .box h3{font-size: 20px; color: var(--color-primary); margin-bottom: 20px;}
    .box h3:after{content: ''; display: block; margin-top: 5px; width: 40px; height: 3px; background: var(--color-quaternary);}

    /* Chart */
    .box.Chart{margin-bottom: 50px;}
    .box.Chart img{display: none;}
    
    @media (max-width: 576px){.box.Chart .Chart-mobile{display: block;}}
    @media (min-width: 576px) and (max-width: 992px){.box.Chart .Chart-tablet{display: block;}}
    @media (min-width: 992px){.box.Chart .Chart-desktop{display: block;}}
    
    
    /* CARDS */
    .cardsDash{margin-bottom: 50px; display: flex; flex-wrap: wrap; text-shadow: 1px 1px 2px rgba(0,0,0,.4);}
    /* .cardsDash .cards{display: flex; flex-wrap: wrap;} */
    /* .cardsDash .cards .card{margin: 6px; padding: 12px; width: calc(100% / 2 - 12px)} */
    .cardsDash .cards .card{border-radius: 0; margin-bottom: 0; padding: 12px; width: 100%;}
    .cardsDash .cards .card:last-child{border-radius: 0 0 12px 12px;}
    .cardsDash .cards .card h2{font-family: var(--font-primary-semibold); color: var(--color-text-secondary); text-align: center; font-size: 14px; margin: 0;}
    
    /* .cardsDash .cards .card-content{display: flex; justify-content: space-between; align-items: center;} */
    .cardsDash .cards .card-content{display: flex; align-items: center;}
    .cardsDash .cards .card-content p{font-size: 12px; line-height: 14px; margin-bottom: 6px; color: var(--color-text-tertiary);}
    .cardsDash .cards .card-content h4{font-family: var(--font-primary-semibold); font-size: 14px; line-height: 16px; color: var(--color-text-tertiary); margin: 0;}
    /* .cardsDash .cards .card-content .icon{display: block; width: 32px; height: 32px; border-radius: 50%; background-color: rgba(255,255,255,1);} */
    .cardsDash .cards .card-content .icon{display: block; width: 36px; height: 36px; padding: 7px; border-radius: 5px; margin-right: 12px; background-color: rgba(0,0,0,0.1);}

    .cardsDash .cards .card.card-lnk{cursor: pointer;}

    
    /* GROUP CARDS */
    .cardsDash .group-cards{width: 100%; padding: 0 10px; margin-bottom: 30px;}
    .cardsDash .group-cards .header-group{font-family: var(--font-primary-semibold); font-size: 16px; line-height: 26px; color: var(--color-text-tertiary); padding: 12px; border-radius: 12px 12px 0 0;}

    .group-cards.group-wins .header-group{background-color: rgba(74,174,87,1);}
    .group-cards.group-wins .box.card:nth-of-type(1){background-color: rgba(74,174,87,.90);}
    .group-cards.group-wins .box.card:nth-of-type(2){background-color: rgba(74,174,87,.84);}
    .group-cards.group-wins .box.card:nth-of-type(3){background-color: rgba(74,174,87,.78);}
    .group-cards.group-wins .box.card:nth-of-type(4){background-color: rgba(74,174,87,.72);}
    .group-cards.group-wins .box.card:nth-of-type(5){background-color: rgba(74,174,87,.66);}
    .group-cards.group-wins .box.card:nth-of-type(6){background-color: rgba(74,174,87,.60);}
    .group-cards.group-wins .box.card:nth-of-type(7){background-color: rgba(74,174,87,.54);}

    .group-cards.group-progress .header-group{background-color: rgba(235,104,52,1);}
    .group-cards.group-progress .box.card:nth-of-type(1){background-color: rgba(235,104,52,.90);}
    .group-cards.group-progress .box.card:nth-of-type(2){background-color: rgba(235,104,52,.84);}
    .group-cards.group-progress .box.card:nth-of-type(3){background-color: rgba(235,104,52,.78);}
    .group-cards.group-progress .box.card:nth-of-type(4){background-color: rgba(235,104,52,.72);}
    .group-cards.group-progress .box.card:nth-of-type(5){background-color: rgba(235,104,52,.66);}
    .group-cards.group-progress .box.card:nth-of-type(6){background-color: rgba(235,104,52,.60);}
    .group-cards.group-progress .box.card:nth-of-type(7){background-color: rgba(235,104,52,.54);}

    .group-cards.group-loss .header-group{background-color: rgba(216,25,25,1);}
    .group-cards.group-loss .box.card:nth-of-type(1){background-color: rgba(216,25,25,.90);}
    .group-cards.group-loss .box.card:nth-of-type(2){background-color: rgba(216,25,25,.84);}
    .group-cards.group-loss .box.card:nth-of-type(3){background-color: rgba(216,25,25,.78);}
    .group-cards.group-loss .box.card:nth-of-type(4){background-color: rgba(216,25,25,.72);}
    .group-cards.group-loss .box.card:nth-of-type(5){background-color: rgba(216,25,25,.66);}
    .group-cards.group-loss .box.card:nth-of-type(6){background-color: rgba(216,25,25,.60);}
    .group-cards.group-loss .box.card:nth-of-type(7){background-color: rgba(216,25,25,.54);}

    .group-cards.group-commissions .header-group{background-color: rgba(31,53,97,1);}
    .group-cards.group-commissions .box.card:nth-of-type(1){background-color: rgba(31,53,97,.90);}
    .group-cards.group-commissions .box.card:nth-of-type(2){background-color: rgba(31,53,97,.84);}
    .group-cards.group-commissions .box.card:nth-of-type(3){background-color: rgba(31,53,97,.78);}
    .group-cards.group-commissions .box.card:nth-of-type(4){background-color: rgba(31,53,97,.72);}
    .group-cards.group-commissions .box.card:nth-of-type(5){background-color: rgba(31,53,97,.66);}
    .group-cards.group-commissions .box.card:nth-of-type(6){background-color: rgba(31,53,97,.60);}
    .group-cards.group-commissions .box.card:nth-of-type(7){background-color: rgba(31,53,97,.54);}
    

    .box.list-result{margin-bottom: 50px;}
    .list-result{border-spacing: 0; border-collapse: separate;}
    .list-result tbody tr{cursor: pointer;}
    .list-result tr.list td:first-child{border-radius: 10px 0 0 12px; padding-left: 10px;}
    .list-result tr.list td.last{border-radius: 0 10px 12px 0;}
    
    .list-result tr.list td{border-top: 2px solid #fff; border-bottom: 2px solid #fff;}


    .list-result tr.list.wins td span{color: var(--color-secondary)}
    .list-result tr.list.wins td.stage span{background: var(--color-secondary)}

    .list-result tr.list.draw td span{color: #fc8252}
    .list-result tr.list.draw td.stage span{background: #fc8252}

    .list-result tr.list.loss td span{color: var(--color-cancel)}
    .list-result tr.list.loss td.stage span{background: var(--color-cancel);}

    .list-result tr td.fullName span{color: var(--color-text-secondary) !important}
    
    .list-result tr.list td.stage span{display: block; width: 100%; text-align: center; font-size: 12px; color: var(--color-text-tertiary); padding: 3px 5px; border-radius: 5px;}



    .purchase-history-header tr{background: #eee;}
    .purchase-history-header tr th{color: var(--color-primary); font-size: 12px; white-space: nowrap; text-align: center; border-bottom: 6px solid #fff;}
    .purchase-history-header tr th.fullName{text-align: left;}
    .purchase-history-header tr th.fiat{text-align: right;}
    .purchase-history-header tr th.fiat .short{display: none;}

    .purchase-history-header tr th:nth-of-type(1){padding-left: 10px;}
    .purchase-history-header tr th.last{padding-right: 10px;}

    .modal .btnPrimary {margin-top: 40px;}

    .twoColumns{display: flex; width: 100%; text-align: left;}
    .twoColumns label{width: 50%;}
    .twoColumns label:nth-of-type(1){padding-right: 5px;}
    .twoColumns label:nth-of-type(2){padding-left: 5px;}

    @media (max-width: 992px){
        .hide-tablet{display: none !important;}
        .purchase-history-header tr th.last-tablet{padding-right: 10px;}
        .list-result tr.list td.last-tablet{border-radius: 0 10px 12px 0; padding-right: 10px;}
    }
    
    @media (max-width: 576px){
        .modal .twoColumns{flex-direction: column;}
        .modal .twoColumns label{width: 100%; padding: 0;}
    }

    @media (min-width: 768px){
        .twoTables{display: flex;}
        .twoTables .box-leads-progress{width: 45%; margin-right: 20px;}
        .twoTables .box-sales-progress{width: 55%;}
    }


    .box.success-withdraw{background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; border-radius: 12px; margin: 40px 0 10px 0;}
    .box.success-withdraw h5{color: var(--color-text-tertiary); margin-bottom: 20px;}
    .box.success-withdraw p{margin-bottom: 0; color: var(--color-text-tertiary);}

    .list-withdraw .form-group-check label{display: flex; align-items: center; margin-bottom: 10px;}
    .list-withdraw .form-group-check label strong{line-height: 16px; margin-right: 15px;}
    .list-withdraw .form-group-check label small{line-height: 16px; color: #999}

    .total-withdraw{font-family: var(--font-primary-semibold); font-size: 16px; line-height: 48px;}

    .cards-dash{display: flex; align-items: flex-start;}
    .cards-dash h2{font-family: var(--font-primary-semibold); color: var(--color-primary); font-size: 12px; margin: 0 0 3px 0;}
    .cards-dash .card-content{display: flex; justify-content: center; align-items: center; flex-direction: column; margin: 0 15px 20px 0; background: var(--color-text-tertiary); border-radius: 12px; padding: 20px 15px; min-width: 120px; height: 80px;}
    .cards-dash .card-content h4{font-family: var(--font-primary-semibold); color: var(--color-secondary); font-size: 16px; margin: 0 0 3px 0;}
    .cards-dash .card-content p{margin: 0; font-size: 12px; color: #666666;}

    
    @media (max-width: 768px){
        .latest-sales .list-result .campaign,
        .latest-sales .list-result .method,
        .latest-sales .list-result .statusPayment{display: none !important;}

        .list-result .list span{font-size: 10px !important;}

        .list-result .purchase-history-header tr th{font-size: 10px !important;}
        .list-result .purchase-history-header tr th.fiat .full{display: none;}
        .list-result .purchase-history-header tr th.fiat .short{display: block; text-align: center;}

        .list-result .list span{display: block; line-height: 13px;}
    }

    @media (min-width: 576px){
        .cardsDash .group-cards{width: 50%;}
    }

    @media (min-width: 992px){
        .cardsDash{flex-wrap: nowrap;}
        .cardsDash .group-cards{width: 25%;}
    }

    @media (min-width: 992px) and (max-width: 1200px){
        .cardsDash .cards .card-content p{font-size: 11px;}
    }
</style>