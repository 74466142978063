<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            
            <ion-content>
                <div class="content ion-padding">
                    
                    <div class="load-green" v-if="!showContent && !errorsGet.agentSign"><span></span></div>

                    <div class="box-error" v-if="errorsGet.agentSign != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataAgentSign}}
                        </div>
                    </div>
                    
                    <div class="box-error" v-if="errorsPost.agentSign != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsPost.dataAgentSign}}
                        </div>
                    </div>
                    
                    <!-- SIGNATURE -->
                    <div class="signature box" v-if="showContent && !errorsGet.agentSign">
                        <iframe :src="urlContract" frameborder="0"></iframe>
                    </div>


                    <!-- ACCEPT -->
                    <div class="accepts" v-if="showContent && !errorsGet.agentSign">
                        <div class="form-group-check">
                            <input type="checkbox" id="acceptTerms" class="form-check" v-model="signed">
                            <label for="acceptTerms" class="form-check-label"><small>{{ getMyLanguage("activate","activate.signet-contract") }}</small></label>
                        </div>
                    
                        <div class="btn-primary disabled" id="btnRegister" v-if="!signed">{{ getMyLanguage("activate","activate.btn") }}</div>
                        <div class="btn-primary" id="btnRegister" v-if="signed" @click="confirmSign()">{{ getMyLanguage("activate","activate.btn") }}</div>
                    </div>

                </div>
            </ion-content>
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/api.js'
    
    export default {
        components: {
            IonApp, IonPage, IonContent,
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,
                signed: false,

                urlContract: "",
                dataAgentSign: {},

                errorsGet: {
                    agentSign: false
                },

                errorsPost: {
                    agentSign: false
                }
            }
        },
        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            confirmSign(){
                api.post('/api/v1/agent/contract/sign')
                .then(response => {
                    window.location.href =  this.flagSelected + '/dashboard/'
                    console.log(response)
                })
                .catch(error => {
                    this.errorsPost.dataAgentSign = error.response.data
                    this.errorsPost.agentSign = true
                })
            }
        },


        async mounted () {
            this.flagUrl();

            await api.get('/api/v1/agent/contract/sign')
            .then(response => {
                this.dataAgentSign = response.data
                this.showContent = true
                
                this.urlContract = this.dataAgentSign.urlToSign
            })
            .catch(error => {
                this.errorsGet.dataAgentSign = error.response.data
                this.errorsGet.agentSign = true
            })
        }
    }
</script>


<style scoped>
    .title-secondary{margin-bottom: 30px;}
    .title-secondary small{display: block; margin: 3px 0 10px;}
    .accepts{display: flex; flex-direction: column; align-items: center;}
    .accepts .form-group-check{margin-bottom: 20px;}
    .accepts .btn-primary{max-width: 400px;}
    
    
    .signature iframe{width: 100%;}
    @media (min-width: 576px){
        .signature iframe{height: 75vh}
    }
    @media (max-width: 576px){
        .signature iframe{height: 80vh}
    }

</style>