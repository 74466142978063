<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <ion-content>
                <div class="content">
                    
                    <div class="loading-activate" v-if="load"><span></span></div>

                    <div class="box-error" v-if="!load && errorsGet.dataInvite != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error", errorsGet.dataInvite) }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataInvite}}
                        </div>
                    </div>
                    <!-- <div class="overlay-error" v-if="!load && errorsGet.dataInvite != false"></div> -->
                    
                    <div class="content-activate ion-padding" v-if="!load && !errorsGet.dataInvite">
                        <div class="logo-dne-activate">
                            <img src="@/assets/images/logo-dne.svg">
                        </div>

                        <h3 class="title-secondary">{{ getMyLanguage("activate","activate.title") }}</h3>

                        <div class="twoColumns">
                            <div class="col-activate col-contract">
                                <div class="terms-contract">
                                    <iframe :src='urlContract' frameborder="0"></iframe>

                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptReadAccept" class="form-check" v-model="accept.readAccept">
                                        <label for="acceptReadAccept" class="form-check-label"><small>{{ getMyLanguage("activate","activate.read-accept") }}</small></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-activate col-data">

                                <div class="box">
                                    <p><strong>{{ getMyLanguage("profile","profile.label.regionName") }}: </strong>{{ getMyLanguage("status", dataInvite.region) }}</p>
                                    <p><strong>{{ getMyLanguage("profile","profile.label.agentType") }}: </strong>{{ getMyLanguage("status", dataInvite.agentType) }}</p>
                                </div>
                                
                                <div class="box-registration">
                                    <div class="formBox">
                                        <h5 class="msg-activate">{{ getMyLanguage("activate","activate.alert") }}</h5>
                                        <label class="formFullName">
                                            <input v-model="newRecruit.fullName" type="text" class="form" :class="{errorForm: errorsForm.fullName.empty || errorsForm.fullName.incomplete}" @blur="(errorsForm.fullName.empty = false), (errorsForm.fullName.incomplete = false)" :placeholder='this.getMyLanguage("register", "agent.label.fullName")' maxlength="80" autocomplete="off" >
                                        </label>
                                        <label class="formYourEmail">
                                            <input v-model="newRecruit.email" type="email" :class="{errorForm: errorsForm.email.empty || errorsForm.email.invalid || errorsForm.email.inUse}" @blur="(errorsForm.email.empty = false), (errorsForm.email.invalid = false), (errorsForm.email.inUse = false)" id="email" class="form formYourEmail" :placeholder='this.getMyLanguage("register", "agent.label.email")'  maxlength="60" autocomplete="off">
                                        </label>

                                        <div class="autocomplete formResidenceCountry">
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus === true" v-on:click="dropListCountry(false)"></div>
                                            <input type="text" id="residenceCountry" class="form" :class="{errorForm: errorsForm.country.empty}" @blur="(errorsForm.country.empty = false)" @focus="dropListCountry(true)" @click="dropListCountry(true)" v-on:keyup="filterList" :value="selectedCountry.name" :placeholder='this.getMyLanguage("register", "agent.label.residenceCountry")' title="Residence country" autocomplete="off">
                                            <ul id="drop-list" v-if="listCountryStatus === true">
                                                <li v-for="(itemList,index) in dataCountry" :key="index" v-on:click="(newRecruit.countryId = itemList.id), (newRecruit.phoneCountryId = itemList.id), (this.changeMask()), (selectedCountryDDI.countrySelected = true), (selectedCountryDDI.active = 'active'), (selectedCountryDDI.phoneMask = itemList.phoneMask), (selectedCountryDDI.ddi = itemList.ddi), (selectedCountryDDI.flag = itemList.flag), (selectedCountry.name = itemList.name), (dropListCountry(false))">
                                                    <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + itemList.flag + "/flags"'>
                                                    <a>{{itemList.name}}</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="formPhone"  :class="{errorForm: errorsForm.phoneNumber.empty || errorsForm.phoneNumber.invalid || errorsForm.phoneNumber.inUse}" @blur="(errorsForm.phoneNumber.empty = false), (errorsForm.phoneNumber.invalid = false), (errorsForm.phoneNumber.inUse = false)">
                                            <div class="select-ddi" v-if="selectedCountryDDI.countrySelected === true && selectedCountryDDI.changeMask === true">
                                                <div class="open-select-ddi" @click="selectedCountryDDI.openSelect = true"></div>
                                                <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect === true" @click="selectedCountryDDI.openSelect = false"></div>
                                                <div class="selected-ddi">
                                                    <div class="flag">
                                                        <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + selectedCountryDDI.flag + "/flags"' v-if="selectedCountryDDI.flag != ''">
                                                    </div>
                                                    <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                                </div>
                                                <ul class="list-ddi" v-if="selectedCountryDDI.openSelect === true">
                                                    <li v-for="(ddiList,index) in dataCountry" :key="index" @click="(newRecruit.phoneCountryId = ddiList.id), (selectedCountryDDI.phoneMask = ddiList.phoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.ddi), (selectedCountryDDI.flag = ddiList.flag), (selectedCountryDDI.openSelect = false)">
                                                        <div class="flag">
                                                        <img class="anime" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + ddiList.flag + "/flags"'>
                                                        </div>
                                                        <span class="name-country">{{ddiList.name}}</span>
                                                        <span class="ddi" >(+{{ddiList.ddi}})</span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <label v-if="selectedCountryDDI.changeMask === true">
                                                <input v-model="newRecruit.phoneNumber" id="phoneNumber" type="text" class="form" v-mask="selectedCountryDDI.phoneMask" :placeholder='this.getMyLanguage("register", "agent.label.phoneNumber")' maxlength="25" autocomplete="off" >
                                            </label>
                                        </div>


                                        <h5 class="msg-activate">{{getMyLanguage("activate","activate.alert-password")}}</h5>

                                        <div class="enterPass">
                                            <label class="formPassword">
                                                <input v-model="newRecruit.password" type="password" v-if="!showPass" class="form" :class="{errorForm: errorsForm.password.empty || errorsForm.password.incomplete}" @blur="(errorsForm.password.empty = false), (errorsForm.password.incomplete = false)" :placeholder='this.getMyLanguage("register", "agent.label.password")' maxlength="16" autocomplete="off" >
                                                <input v-model="newRecruit.password" type="text" v-if="showPass" class="form" :class="{errorForm: errorsForm.password.empty || errorsForm.password.incomplete}" @blur="(errorsForm.password.empty = false), (errorsForm.password.incomplete = false)" :placeholder='this.getMyLanguage("register", "agent.label.password")' maxlength="16" autocomplete="off" >
                                            </label>

                                            <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                            <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                        </div>

                                        <div class="msgError">
                                            <!-- {{errorsForm}} -->
                                            <span v-if="errorsForm.fullName.empty">{{ getMyLanguage("errors", "errors.fullNameError.empty") }}</span>
                                            <span v-if="errorsForm.fullName.incomplete">{{ getMyLanguage("errors", "errors.fullNameError.incomplete") }}</span>
                                            <span v-if="errorsForm.email.empty">{{ getMyLanguage("errors", "errors.emailError.empty") }}</span>
                                            <span v-if="errorsForm.email.invalid">{{ getMyLanguage("errors", "errors.emailError.invalid") }}</span>
                                            <span v-if="errorsForm.email.inUse">{{ getMyLanguage("errors", "errors.emailError.inUse") }}</span>
                                            <span v-if="errorsForm.country.empty">{{ getMyLanguage("errors", "errors.countryError.empty") }}</span>
                                            <span v-if="errorsForm.phoneNumber.empty">{{ getMyLanguage("errors", "errors.phoneError.empty") }}</span>
                                            <span v-if="errorsForm.phoneNumber.invalid">{{ getMyLanguage("errors", "errors.phoneError.invalid") }}</span>
                                            <span v-if="errorsForm.phoneNumber.inUse">{{ getMyLanguage("errors", "errors.phoneError.inUse") }}</span>
                                            <span v-if="errorsForm.password.empty">{{getMyLanguage("errors","errors.password.empty")}}</span>
                                            <span v-if="errorsForm.password.invalid">{{getMyLanguage("errors","errors.password.invalid")}}</span>
                                        </div>

                                        <div class="accepts">
                                            <div class="form-group-check">
                                                <input type="checkbox" id="acceptTerms" class="form-check" v-model="accept.terms">
                                                <label for="acceptTerms" class="form-check-label">
                                                    <small>{{ getMyLanguage("activate","activate.acceptTerms") }}</small>
                                                    <a :href='"https://dne.international/" + flagSelected + "/terms-and-conditions"' target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("activate", "activate.acceptTerms.lnk") }}</a>
                                                </label>
                                            </div>
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                                <label for="acceptNotify" class="form-check-label"><small>{{ getMyLanguage("activate","activate.acceptNotify") }}</small></label>
                                            </div>
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                                <label for="acceptGDPR" class="form-check-label"><small>{{ getMyLanguage("activate","activate.acceptGdpr") }}</small></label>
                                            </div>
                                        </div>

                                        <div class="btn-primary disabled" id="btnRegister" v-if="!accept.readAccept || !accept.terms || !accept.notify || !accept.gdpr">{{ getMyLanguage("activate","activate.btn") }}</div>
                                        <div class="btn-primary" id="btnRegister" v-if="accept.readAccept && accept.terms && accept.notify && accept.gdpr && !validating" @click="validateForm()">{{ getMyLanguage("activate","activate.btn") }}</div>

                                        <div class="btn-primary btn-loading" v-if="validating"></div>

                                        <!-- {{newRecruit}} -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';

    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/api.js'

    import {mask} from 'vue-the-mask'

    export default {
        components: {
            IonApp,IonPage, IonContent,
        },

        directives: {mask},

        data () {
            return {
                errorsGet: {
                    dataInvite: false
                },
                dataActivate: [],
                load: true,
                accept: {
                    readAccept: false,
                    terms: false,
                    notify: false,
                    gdpr: false
                },
                validating: false,

                showPass: false,

                urlContract: '',

                newRecruit: {},
                errorsForm: {
                    fullName: {empty: false, incomplete: false},
                    email: {empty: false, invalid: false, inUse: false},
                    country: {empty: false},
                    phoneNumber: {empty: false, invalid: false, inUse: false},
                    password: {empty: false, invalid: false}
                },

                dataCountry: [],
                flagSelected: {},
                dataLanguage: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryDDI: {
                    changeMask: false,
                    phoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect

                console.log("idioma: " + flagSelect)
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            generateContract(value){
                const idInvite = value
                this.urlContract = "/en/agent/invite-contract/" + idInvite
            },

            startMaskPhone(){
                // PUXAR PAIS DO SELLER AUTOMATICAMENTE
                this.selectedCountryDDI.phoneMask = '### ### ###'
                this.selectedCountryDDI.flag = "58bfc699-7e06-4362-3445-c598e9232900"
                this.selectedCountryDDI.ddi = "351"
                this.selectedCountryDDI.countrySelected = true
                this.selectedCountryDDI.active = 'active'
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    const maskPhone = this.selectedCountryDDI.phoneMask
                    
                    this.selectedCountryDDI.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            dropListCountry(status){
                this.listCountryStatus = status
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("residenceCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            validateForm(){
                if ( this.newRecruit.fullName === undefined || this.newRecruit.fullName === "" ){
                    this.errorsForm.fullName.empty = true
                } else if ( this.newRecruit.fullName != undefined || this.newRecruit.fullName != "" ){
                    let fullName = this.newRecruit.fullName
                    fullName = fullName.split(' ').length;
                    if ( fullName < 2 ){
                        this.errorsForm.fullName.incomplete = true
                    } else{
                        this.errorsForm.fullName.incomplete = false
                    }
                } else{
                    this.errorsForm.fullName.empty = false
                }


                // E-MAIL
                if ( this.newRecruit.email === undefined || this.newRecruit.email === "" ){
                    this.errorsForm.email.empty = true
                } else{
                    this.errorsForm.email.empty = false
                }

                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.newRecruit.email)) {
                    this.errorsForm.email.invalid = false
                } else {
                    this.errorsForm.email.invalid = true
                }

                // COUNTRY ID
                if ( this.newRecruit.countryId === undefined || this.newRecruit.countryId === "" ){
                    this.errorsForm.country.empty = true
                } else{
                    this.errorsForm.country.empty = false
                }
                
                // PHONE NUMBER
                if ( this.newRecruit.phoneNumber === undefined || this.newRecruit.phoneNumber === "" ){
                    this.errorsForm.phoneNumber.empty = true
                } else{
                    this.errorsForm.phoneNumber.empty = false
                }

                // PASSWORD
                if ( this.newRecruit.password === undefined || this.newRecruit.password === "" ){
                    this.errorsForm.password.empty = true
                } else{
                    this.errorsForm.password.empty = false
                }

                // ERRORS
                if ( !this.errorsForm.fullName.empty && !this.errorsForm.fullName.incomplete && !this.errorsForm.email.empty && !this.errorsForm.email.invalid && !this.errorsForm.email.inUse && !this.errorsForm.country.empty && !this.errorsForm.phoneNumber.empty && !this.errorsForm.phoneNumber.invalid && !this.errorsForm.phoneNumber.inUse ){
                    this.validating = true

                    setTimeout(() => {
                        this.register()
                    }, 500);
                } else{
                    this.validating = false
                }
            },

            // register(){
            //     window.alert("form validado")
            // }

            register(){
                const register = this.newRecruit;

                api.post('/api/v1/agent/invite/' + this.$route.params.registerId, register)
                .then(response => {
                    const responseData = response.data.accessToken

                    localStorage.setItem('accessToken',responseData);
                    window.location.href =  this.flagSelected + '/dashboard/'


                    // this.registerId = response.data.id;
                    // const languageRedirect = String(window.location.pathname).split("/")[1];
                    // window.location.href = '/' + languageRedirect + '/agent/invite/success/'
                })
                .catch(error => {

                    this.errorsGet.dataAccount = error.response.status

                    if ( this.errorsGet.dataAccount == 401 ){
                        this.errorsForm.password.invalid = true
                    } else{
                        this.validating = false
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                        console.log(error.response.data)
    
                        this.$toast.error(error.response.data);
    
                        setTimeout(this.$toast.clear, 5000)
                    }

                    this.validating = false
                })
            },
        },


        async mounted () {           
            this.flagUrl();


            await api.get('/api/v1/agent/invite/' + this.$route.params.registerId)
            .then(response => {
                this.dataInvite = response.data
                this.registerId = response.data.id;

                this.newRecruit.accountProfileId = this.dataInvite.accountProfileId
                this.newRecruit.regionId = this.dataInvite.regionId
                this.newRecruit.agentTypeId = this.dataInvite.agentTypeId

                this.generateContract(this.dataInvite.accountProfileId);
                
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.status

                // if (error.response.status === 404) {
                //     window.location.href = "/" + this.flagSelected + "/404"
                // }
            })

            

            const resumeLanguage = await api.get('api/v1/platform/language')
            this.dataLanguage = resumeLanguage.data.filter(lang => lang.code === this.flagSelected)[0]

            setTimeout(() => {
                this.newRecruit.languageId = this.dataLanguage.id
            }, 3000);

            const resumeClientCountry = await api.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            this.startMaskPhone()
            this.showContent = true
            

            setTimeout(() => {
                // this.newRecruit.inviteId = this.dataInvite.id
                this.load = false
            }, 500);
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .loading-activate{position: fixed; z-index: 9999; left: 0; top: 0; width: 100%; height: 100%;}
    .loading-activate span{position: absolute; left: 50%; top: 50%; width: 120px; height: 120px; transform: translateX(-50%) translateY(-50%); background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;}

    .logo-dne-activate{width: 50%; max-width: 240px; margin: 0 auto 40px auto;}

    .scroll-vertical{height: 300px; border-radius: 12px;}

    .terms-contract{padding: 10px 8px 10px 16px; background: #fff; border-radius: 12px;}
    .terms-contract iframe{width: 100%; overflow-x: hidden;}
    @media (max-width: 992px){
        .terms-contract iframe{height: 50vh;}
    }
    @media (min-width: 992px){
        .terms-contract iframe{height: 567px;}
    }
    .terms-contract .form-check-label{margin-top: 30px;}

    .col-data{margin-top: 50px;}
    .col-data h4{color: var(--color-primary);}
    .col-data .msg-activate{margin-bottom: 15px;}
    .col-data .box{background: rgba(255,255,255,0.4); margin: 35px 0;}
    .col-data .box p{margin-bottom: 7px;}
    .col-data .box p:last-child{margin-bottom: 0;}

    .item-buttons{margin-top: 35px;}



    .col-activate .box{margin: 0 0 30px 0;}
    /* .msg-activate{} */


    /* .box-registration{} */
    .formBox{margin-bottom: 30px;}
    .formBox label:not(.form-check-label), .formBox .autocomplete{position: relative; margin-bottom: 10px;}
    .formBox label:not(.form-check-label):before, .formBox .autocomplete:before{content: ''; display: block; width: 20px; height: 20px; background-size: 20px auto !important; position: absolute; top: 50%; left: 15px; transform: translateY(-50%);}
    .formBox input, .formBox select{padding-left: 50px; margin-bottom: 0;}
    
    .form{border: 1px solid #f7f7f7;}
    .formFullName:before{background: url(~@/assets/images/icons/form-register-fullname.svg) no-repeat center;}
    .formYourEmail:before{background: url(~@/assets/images/icons/form-register-your-email.svg) no-repeat center;}
    .formResidenceCountry:before{background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center;}
    .formPassword:before{background: url(~@/assets/images/icons/login-password.svg) no-repeat center;}
    
    .formPhone{display: flex; align-items: center; margin-bottom: 30px;}
    .formPhone label{position: relative; display: block; width: 100%; margin-bottom: 10px;}
    .formPhone label:before{display: none !important;}
    /* .formPhone label:before{content: ''; display: block; width: 20px; height: 20px; background: url(~@/assets/images/icons/form-register-phone.svg) no-repeat center; background-size: 20px auto !important; position: absolute; top: 50%; left: 15px; transform: translateY(-50%);} */
    .formPhone input{display: block; border-left: none; border-radius: 0 12px 12px 0; padding: 0 1rem 0 5px;}
    .formPhone input:focus{-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;}
    .formPhone.errorForm{border: none !important; background: none !important;}
    .formPhone.errorForm .select-ddi{border: 1px solid rgba(216,25,25,0.7); border-right: none; background-color: #f3eeee;}
    .formPhone.errorForm .form{border: 1px solid rgba(216,25,25,0.7); border-left: none; background-color: #f3eeee;}

    .form::-webkit-input-placeholder{color: #999;}
    .form::-moz-placeholder{color: #999;}
    .form::-ms-input-placeholder{color: #999;}

    .autocomplete ul{top: calc(100% + 5px);}
    .autocomplete ul li{display: flex; align-items: center;}
    .autocomplete ul li img{width: 25px}

    .select-ddi{display: inline-flex; position: relative; padding-left: 50px; margin: 2px 0 10px 0; height: 48px; border: none; background: #f7f7f7; border-radius: 12px 0 0 12px;}
    .select-ddi:before{content: ''; display: block; width: 20px; height: 20px; background: url(~@/assets/images/icons/form-register-phone.svg) no-repeat center; background-size: 20px auto !important; position: absolute; top: 50%; left: 15px; transform: translateY(-50%);}
    .select-ddi .selected-ddi{display: flex; align-items: center; flex-direction: row;}
    .select-ddi .selected-ddi .flag{margin-right: 5px; position: relative; width: 30px;}
    .select-ddi .selected-ddi .flag img{display: block; width: 30px; height: auto;}
    .select-ddi .selected-ddi .flag:after{content: ''; display: block; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #888888; position: absolute; left: 0; bottom: -8px;}
    .select-ddi .selected-ddi .ddi{display: block; font-size: 15px;}

    .select-ddi .open-select-ddi, .select-ddi .close-select-ddi{position: absolute; z-index: 20; left: 0; top: 0; width: 100%; height: 100%; cursor: pointer;}
    .select-ddi .close-select-ddi{z-index: 30;}
    .select-ddi .list-ddi{position: absolute; z-index: 20; left: 0; top: 108%; background: var(--color-text-tertiary); padding: 10px 20px; margin: 0; border-radius: 12px; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);}
    .select-ddi .list-ddi li{display: inline-flex; align-items: center; border-bottom: 1px solid var(--color-tertiary); line-height: 32px; cursor: pointer;}
    .select-ddi .list-ddi li:last-child{border-bottom: none;}
    .select-ddi .list-ddi li .flag{width: 25px; margin-right: 10px;}
    .select-ddi .list-ddi li .flag img{display: block; width: 100%; height: auto;}
    .select-ddi .list-ddi li .name-country{font-size: 14px;}
    .select-ddi .list-ddi li .ddi{margin-left: 5px; font-size: 12px;}

    .select-ddi .load{width: 40px; height: 40px; margin: 0 0 14px 0;}
    .select-ddi .d-flex{justify-content: space-between; align-items: center;}
    .select-ddi .btn-primary{font-size: 14px; padding: 0 20px;}

    .accepts{margin-top: 20px;}
    .accepts .form-group-check{margin: 7px 0;}

    .btn-primary{margin-top: 30px;}

    .form-group-check .form-check+label:before{width: 30px; height: 30px; border-radius: 8px; background-size: 18px auto !important;}
    .form-group-check .form-check:checked+label:before{background-size: 12px auto !important;}
    .form-check-label{display: flex; align-items: center; padding-left: 40px; line-height: 30px; height: 30px; list-style: 14px;}
    .form-check-label small{font-size: 14px; line-height: 16px; color: var(--color-text-secondary);}

    @media (max-width: 992px){
        .form-group-check .form-check+label:before{width: 26px; height: 26px; border-radius: 6px; background-size: 16px auto !important;}
        .form-group-check .form-check:checked+label:before{background-size: 10px auto !important;}
        .form-check-label{padding-left: 34px; line-height: 16px; height: 28px; margin-top: 2px;}
        .form-check-label small{font-size: 12px;;}
    }

    @media (min-width: 992px){
        .title-secondary{margin-bottom: 50px;}

        .twoColumns{display: flex; width: 100%;}
        .twoColumns .col-activate{width: 50%;}
        .twoColumns .col-activate:nth-of-type(1){padding-right: 35px;}
        .twoColumns .col-activate:nth-of-type(2){padding-left: 35px;}
        
        .col-data{margin-top: 0;}
    }
</style>