<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    <h3 class="title-secondary">{{ getMyLanguage("leads", "leads.title.success") }} Success</h3>
                    <div class="success">
                        <h2>Data saved successfully</h2>
                    </div>
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },
    }
</script>


<style scoped>
    .success{position: relative; background: var(--color-secondary); border-radius: 12px; padding: 41px 16px 20px 16px; margin-top: 60px; position: relative; z-index: 10; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);}
    .success:before{content: ''; display: block; position: absolute; left: 50%; top: -25px; transform: translateX(-50%); width: 50px; height: 50px; background: url('~@/assets/images/icons/step-status-validated.svg') no-repeat center var(--color-text-tertiary); background-size: 50% auto; border-radius: 50%;}
    .success h2{margin: 0; text-align: center; color: var(--color-text-tertiary);}
</style>