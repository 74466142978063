<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    <h3 class="title-secondary">{{ getMyLanguage("manuals", "manuals.title") }}</h3>

                    <div class="manuals">
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.whitepaper") }} <small>(PDF) 21/11/22</small></h3>
                            <a :href='"https://dne.international/WhitePaper-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>

                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.ecosystem-presentation") }} <small>(Youtube) 04/11/22</small></h3>
                            <div @click="modalvideo = true" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.watch-video") }}</div>
                        </div>
                        
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.company-formation-pdf") }} <small>(PDF) 18/10/22</small></h3>
                            <a :href='"/formacao-empresa-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>
                        
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.company-formation-short-pdf") }} <small>(PDF) 22/11/22</small></h3>
                            <a :href='"/apresentacao-curta-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>
                        
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.memo") }} <small>(PDF) 15/11/22</small></h3>
                            <a :href='"/memo-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>

                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.flowchart.POS") }} <small>(JPG) 12/09/22</small></h3>
                            <a :href='"/fluxograma_DNE-POS.png?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>
                        
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.flowchart.resale-boxes") }} <small>(JPG) 12/09/22</small></h3>
                            <a :href='"/fluxograma_DNE-Revenda-Boxes.png?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>
                        
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.flowchart.private-sale") }} <small>(JPG) 12/09/22</small></h3>
                            <a :href='"/fluxograma_DNE-Venda-Privada.png?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div>
                        
                        <div class="box box-download">
                            <h3>{{ getMyLanguage("manuals","manuals.stellar.dne") }} <small>(LINK) 16/09/22</small></h3>
                            <a href="https://stellar.expert/explorer/public/asset/DNE-GD62YP66LOTG6WBQCTARX3GLCNNLFKOISNS46UUTN5FUO3GFHTTUEEWV" target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.link") }}</a>
                        </div>
                        
                        <!-- <div class="box box-download">
                            <h3 v-html='getMyLanguage("manuals","manuals.company-formation-pptx")'></h3>
                            <a :href='"/formacao-empresa-" + flagSelected + ".pptx"' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                        </div> -->
                    </div>
                </div>
            </ion-content>
                
            <!-- MODAL VIDEO -->
            <div class="modal" v-if="modalvideo">
                <div @click="modalvideo = false" class="close-modal"></div>
                <div class="box cont-modal video-container">
                    <iframe src="https://www.youtube.com/embed/j9Bkzz8bYBs?rel=0&controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div class="overlayModal" v-if="modalvideo"></div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/api.js';

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                dataProfile: [],
                modalvideo: false
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },


        async mounted () {
            this.flagUrl();
            const resumeSeler = await api.get('/api/v1/platform/account/agent')
            this.dataProfile = resumeSeler.data
        }
    }
</script>


<style scoped>
    @media (min-width: 576px){
        .manuals{display: flex; flex-wrap: wrap; justify-content: center; width: 100%;}
        .manuals .box{margin: 10px; width: calc(25% - 20px); background: var(--color-text-tertiary);}
    }
    
    .box{background: none; box-shadow: none;}
    .box h3{font-size: 20px; color: var(--color-primary); margin-bottom: 30px; position: relative; padding-left: 25px;}
    .box h3 small{display: block; margin: 5px 0;}
    .box h3:before{content: ''; display: block; width: 18px; height: 18px; background: var(--color-secondary); border-radius: 50%; position: absolute; left: 0; top: 6px;}
    .box h3:after{content: ''; display: block; width: 60px; height: 3px; background: var(--color-quaternary); margin-top: 3px;}
    .box .btn-download{height: 42px; background: #fff; color: var(--color-secondary); border: 1px solid var(--color-secondary);}
    /* .box .btn-download:after{background: url("~@/assets/images/icons/download-2.svg") no-repeat center; width: 22px; height: 18px;} */
    .box .btn-download:after{display: none;}

    .video-container{position: relative; padding-bottom: 56.25%;}
    .video-container iframe{position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
</style>