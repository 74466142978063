<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    
                    <div class="box-error" v-if="errorsGet.dataReports != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataReports}}
                        </div>
                    </div>

                    <div v-if="showContent && !errorsGet.dataReports">
                        <h3 class="title-secondary" v-if="lengthReport != 'load' && filter === false">{{ getMyLanguage("report", "report.title") }}</h3>
                        
                        <div class="load load-center" v-if="lengthReport === 'load'"></div>
                        
                        <div class="search-bar" v-if="dataReports.length > 0 &&  filter === false">
                            <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("agent","agent.search")'>
                            <!-- <div class="btn-filter" @click="filter = true"></div> -->
                            <div class="btn-add" @click='openReport("00000000-0000-0000-0000-000000000000")' v-if='dataAgent.agentTypeCode != "master_franchising"'>{{getMyLanguage("report","btn-new-report")}}</div>
                        </div>

                        <h3 class="title-secondary" v-if="filter === true">{{ getMyLanguage("report","report.title") }}</h3>
                        <div class="filter box" v-if="filter === true">
                            <div class="close-filter" @click="filter = false"></div>
                            
                            <div class="filters">
                                <div class="form-group-switch">
                                    <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                                    <label for="stage" class="form-switch-label">
                                        <small>Filter name</small>
                                    </label>
                                </div>
                            </div>

                            <div class="btn-primary">Filter</div>
                        </div>


                        <!-- <div class="box" v-if="this.lengthReport === true"> -->
                        <div class="box" v-if="lengthReport">
                            <div class="scroll-horizontal">
                                <table class="list-result">
                                    <thead class="reports-header">
                                        <tr>
                                            <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                            <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                            <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                            <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        </tr>
                                    </thead>

                                    <tbody id="drop-list">
                                        <tr v-for="(reportList,index) in dataReports" :key="index" @click="openReport(reportList.agentReportId)" class="list">
                                            <td class="fullName text-left"><span>{{reportList.agentName}}</span></td>
                                            <td class="date"><span>{{dateTimeMin(reportList.dateStart)}}</span></td>
                                            <td class="date"><span>{{dateTimeMin(reportList.dateEnd)}}</span></td>
                                            <td class="status" :class="reportList.agentReportStatus"><span>{{ getMyLanguage("status","report." + reportList.agentReportStatus) }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="no-results" v-if="!lengthReport">
                            <p>{{ getMyLanguage("report", "no-report") }}</p>
                            <div class="btn-add" v-if='dataAgent.agentTypeCode != "master_franchising" && !lengthReport' @click='openReport("00000000-0000-0000-0000-000000000000")'>{{getMyLanguage("report","btn-new-report")}}</div>
                        </div>
                    </div>

                </div>
            </ion-content>
            

            <!-- MODAL -->
            <div class="modal" v-if="showModal">
                <div @click="closeUpdate()" class="close-modal"></div>
                <div class="box cont-modal phase-modal">
                    <div class="scroll-vertical">

                        <h3>{{getMyLanguage("report","btn-new-report")}}</h3>
                        <!-- <h3>{{getMyLanguage("report","report.reported-by")}} {{dataModal.agentName}}</h3> -->
                        
                        <div class="form-report">
                            <div class="date-report">
                                <label>
                                    {{getMyLanguage("report","date-start")}}
                                    <input type="date" class="form" v-model="dataModal.dateStart" v-if='dataModal.agentReportStatus === "newReport"'>
                                    <h4 v-if='dataModal.agentReportStatus != "newReport"'>{{dateTimeFull(dataModal.dateStart)}}</h4>
                                </label>

                                <label>
                                    {{getMyLanguage("report","date-end")}}
                                    <input type="date" class="form" v-model="dataModal.dateEnd" v-if='dataModal.agentReportStatus === "newReport"'>
                                    <h4 v-if='dataModal.agentReportStatus != "newReport"'>{{dateTimeFull(dataModal.dateEnd)}}</h4>
                                </label>
                            </div>

                            <div class="report">
                                <h4>{{getMyLanguage("report","report.subtitle")}}</h4>

                                <div v-if='dataModal.agentReportStatus === "newReport"'>
                                    <textarea class="form" v-model="dataModal.report"></textarea>
                                </div>

                                <div class="txt" v-if='dataModal.agentReportStatus != "newReport"'>
                                    {{dataModal.report}}

                                    <p v-if='dataModal.agentReportStatus === "Viewed"'><small>{{getMyLanguage("report","report.viewed-by")}} {{dataModal.updatedBy}} {{getMyLanguage("report","report.viewed-in")}} {{dateTimeFull(dataModal.updatedAt)}}</small></p>
                                </div>
                            </div>
                            
                            <div class="reply" v-if='(dataModal.reply != null && dataModal.reply != "") || dataAgent.agentTypeCode === "master_franchising"'>
                                <h4>{{getMyLanguage("report","reply.subtitle")}}</h4>
                                <textarea class="form" v-model="dataModal.reply" v-if='dataAgent.agentTypeCode === "master_franchising"'></textarea>
                                
                                <p v-if='(dataModal.agentReportStatus === "Replyed" || dataModal.agentReportStatus === "Finished") && dataAgent.agentTypeCode != "master_franchising"'>{{dataModal.reply}}</p>
                                <p v-if='(dataModal.agentReportStatus === "Replyed" || dataModal.agentReportStatus === "Finished") && dataAgent.agentTypeCode != "master_franchising"'><small>{{getMyLanguage("report","report.replied by")}} {{dataModal.updatedBy}} {{getMyLanguage("report","report.viewed-in")}} {{dateTimeFull(dataModal.updatedAt)}}</small></p>
                            </div>
                            
                            <div class="bts">
                                <div class="btn-primary" v-if='dataModal.agentReportStatus === "newReport"' @click="send()">{{getMyLanguage("report","btn-send-report")}}</div>
                                <div class="btn-primary" v-if='dataAgent.agentTypeCode === "master_franchising"' @click="send()">{{getMyLanguage("report","btn-send-reply")}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="showModal"></div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import moment from 'moment';
    import api from '@/config/api.js'

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                lengthReport: 'load',
                showContent: false,
                showModal: false,

                dataAgent: [],
                dataReports: {
                    modal: false,
                    dateStart: '',
                    dateEnd: '',
                    report: ''
                },
                dataModal: {},

                errorsGet: {
                    dataReports: false,
                    dataModal: false
                },

                errorsPost: {
                    report: false,
                    reply: false
                },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            dateTime(value) {
                // return moment(value).format('DD/MM/YYYY');
                return moment(value).format('YYYY-MM-DD');
            },
            dateTimeMin(value) {
                return moment(value).format('DD/MM');
            },
            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },


            async reports(){
                await api.get('/api/v1/agent/reports')
                .then(response => {
                    this.dataReports = response.data
                })
                .catch(error => {
                    this.errorsGet.dataReports = error
                })

                this.showContent = true
                
                if ( this.dataReports.length > 0 ){
                    this.lengthReport = true
                } else{
                    this.lengthReport = false
                }
            },

            async openReport(value){
                
                if ( value === "00000000-0000-0000-0000-000000000000"){
                    this.dataModal.agentReportId = value
                    this.dataModal.report = this.getMyLanguage('report','report.msg.newreport')
                    this.dataModal.dateStart = this.dateTime(this.dataModal.dateStart)
                    this.dataModal.dateEnd = this.dateTime(this.dataModal.dateEnd)
                    this.dataModal.agentReportStatus = "newReport"
                    this.showModal = true
                } else {
                    await api.get('/api/v1/agent/reports/' + value)
                    .then(response => {
                        this.dataModal = response.data
                        console.log(this.dataModal)
                        this.showModal = true
                    })
                    .catch(error => {
                        this.errorsGet.dataModal = error
                    })
                }
            },


            async toastSuccess() {
                const toast = await toastController
                    .create({
                    message: 'Sucess',
                    color: "success",
                    duration: 2000
                    })
                return toast.present();
            },


            send(){
                api.post("/api/v1/agent/reports/" + this.dataModal.agentReportId, this.dataModal)
                .then(response => {
                    console.log(response)
                    console.clear()
                    this.toastSuccess()
                    this.reports()
                    this.showModal = false
                })
                .catch(error => {
                    // this.errorsPost.report = JSON.stringify(error.response.status)
                    this.errorsPost.report = error
                })
            },


            closeUpdate(){
                this.reports()
                this.dataModal.reply = null
                this.showModal = false
            }

        },


        async mounted () {
            this.flagUrl();

            await api.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataAgent = response.data
            })
            .catch(error => {
                // this.errorsGet.dataAgent = JSON.stringify(error.response.status)
                
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
                
                if (error.response.status == 409) {
                    window.location.href = '/' + this.flagSelected + '/dashboard/agent-sign'
                }
                
                if (error.response.status == 403) {
                    window.location.href = '/' + this.flagSelected + '/page403'
                }

                else{
                    window.location.href = '/' + this.flagSelected + '/page403'
                }
            })

            this.reports()
        }
    }
</script>


<style scoped>
    .load{width: 40px; height: 40px; margin: 0 auto 25px; background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;}
    .load.load-center{width: 80px; height: 80px; position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); margin: 0;}

    .list-result .list .date{padding-left: 10px;}

    .reports-header tr{background: #eee;}
    .reports-header tr th{color: var(--color-primary); font-size: 12px; white-space: nowrap; border-bottom: 6px solid #fff;}
    .reports-header tr th.fiat{text-align: right;}
    .reports-header tr th.fiat .short{display: none;}

    th.fullName, td.fullName{text-align: left; padding-left: 20px;}


    @media (max-width: 576px){
        .sale-description div{width: 100%;}
        th.fullName, td.fullName{padding-left: 5px;}
    }

    @media (max-width: 768px){
        .list-result .list span{display: block; font-size: 12px; line-height: 13px;}
    }

    .scroll-vertical{max-height: 80vh; border-radius: 12px;}

    .modal .cont-modal{background: #eee;}
    .form-report .report{border-radius: 12px;}
    .form-report .date-report{display: flex; margin-bottom: 20px;}
    .form-report .date-report .form{margin: 0;}
    .form-report .date-report label:first-child{margin-right: 20px;}
    .form-report .date-report label{font-size: 13px; background: #fff; padding: 10px; border-radius: 12px; display: block; width: auto;}
    .form-report .date-report label h4{font-size: 16px; margin: 0;}
    
    .form-report .bts{display: flex; justify-content: center;}
    .form-report .bts .btn-primary{width: auto; padding: 0 30px; margin: 0;}
    
    .form-report .report{background: #fff; padding: 10px;}
    .form-report .report h4{color: var(--color-primary); margin-bottom: 5px;}
    .form-report .report .form{padding: 8px 12px; height: 230px; margin: 0;}
    .form-report .report p{margin: 0;}
    .form-report .report p small{display: block; border-top: 1px solid #eee; padding-top: 10px; margin-top: 10px;}

    .form-report .reply{margin-top: 30px; background: var(--color-primary); padding: 10px 16px; border-radius: 12px;}
    .form-report .reply h4{color: var(--color-text-tertiary); margin-bottom: 5px;}
    .form-report .reply .form{padding: 5px 8px; margin: 0; border-radius: 12px; height: 80px;}
    .form-report .reply p{margin: 0; color: var(--color-text-tertiary)}
    .form-report .reply p small{display: block; border-top: 1px solid rgba(255,255,255,0.3); padding-top: 10px; margin-top: 10px; color: rgba(255,255,255,0.8);}

    .form-report .bts .btn-primary{margin-top: 30px;}

    .no-results .btn-add{display: inline-flex; width: auto; margin: 30px 0 0 0;}
</style>