<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <ion-content>
                <div class="content">
                    <div class="loading-activate" v-if="load"><span></span></div>
                    
                    <div class="content-success ion-padding" v-if="!load">
                        <div class="logo-dne-activate">
                            <img src="@/assets/images/logo-dne.svg">
                        </div>

                        <h3 class="title-secondary">{{ getMyLanguage("activate","title.success") }}</h3>

                        <div class="box">
                            <h4 class="msg-success">{{ getMyLanguage("activate","msg-activation") }}</h4>

                            <p class="msg-login">{{ getMyLanguage("activate","choose-option") }}</p>

                            <div class="login-by">
                                <div class="btn-login web" @click="lnkBrowser()">{{ getMyLanguage("activate","browser") }}</div>
                                <div class="btn-login download-app"><img src="~@/assets/images/app-dne-download-android.svg" alt="Download app for Android"></div>
                                <div class="btn-login download-app"><img src="~@/assets/images/app-dne-download-ios.svg" alt="Download app for Android"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            IonApp,IonPage, IonContent,
        },
        data () {
            return {
                flagSelected: {},
                dataActivate: [],
                load: true,
                accept: false,
                validating: false
            }
        },
        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkBrowser(){
                window.location.href = this.flagSelected + '/login'
            }
        },


        async mounted () {
            // const resumeDataActivate = await api.get('api/v1/platform/account/agent/activate/3fa85f64-5717-4562-b3fc-2c963f66afa6')
            // this.dataActivate = resumeDataActivate.data

            this.flagUrl();

            setTimeout(() => {
                this.load = false
            }, 200);
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .loading-activate{position: fixed; z-index: 9999; left: 0; top: 0; width: 100%; height: 100%;}
    .loading-activate span{position: absolute; left: 50%; top: 50%; width: 120px; height: 120px; transform: translateX(-50%) translateY(-50%); background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;}

    .logo-dne-activate{width: 50%; max-width: 240px; margin: 0 auto 40px auto;}

    .box{background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover;}

    .msg-success{text-align: center; font-size: 20px; color: #fff; font-family: var(--font-primary-semibold); margin: 20px 0 40px 0 !important;}
    .msg-login{text-align: center; color: #fff; margin-bottom: 25px;}

    .login-by{display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom: 20px;}
    .login-by .btn-login{background: var(--color-primary); color: #fff; font-size: 20px; text-align: center; width: 100%; height: 50px; line-height: 50px; margin: 10px 0; border-radius: 8px; overflow: hidden; cursor: pointer;}
    .login-by .btn-login img{display: inline-block; width: auto; height: 50px;}


    @media (min-width: 992px){
        .title-secondary{margin-bottom: 50px;}

        .login-by{flex-direction: row;}
        .login-by .btn-login{width: 150px; height: 50px; line-height: 50px; margin: 0 10px;}
        .login-by .btn-login img{display: block;}
    }
</style>