/* eslint-disable */

import en from '@/locales/en.json';
import es from '@/locales/es.json';
import pt from '@/locales/pt.json';

export default function getMyFavouriteLanguage (group, code){
    
    // if ( localStorage.getItem('lang') != undefined || localStorage.getItem('lang') != null || localStorage.getItem('lang') != '' ){
    //     const language = localStorage.getItem('lang')
    // }
    // else{
    //     language = "en"
    // }
    
    const language = String(window.location.pathname).split("/")[1];

    let lang = [];
    
    if(language === "en"){
        lang = en;
    }
    else if(language === "es"){
        lang = es;
    }
    else if(language === "pt"){
        lang = pt;
    }
    else{
        lang = en;
    }

    return lang.filter((item) => item.group === group).filter((item) => item.code === code).map(item => item.value).join();
}

/* eslint-disable */