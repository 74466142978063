<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    
                    <div class="load-green" v-if="load"><span></span></div>

                    <div class="box-error" v-if="errorsGet.dataDash != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataDash}}
                        </div>
                    </div>
                    
                    <!-- CARDS -->
                    <h3 class="title-secondary" v-if="showContent">
                        {{getMyLanguage("dashboard","dashboard.title")}}
                        <small>{{dataDash.name}}</small>    
                    </h3>
                    
                    <div class="cardsDash" v-if="showContent">

                        <!-- SALES WINS -->
                        <div class="group-cards group-wins">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-wins")}}</div>

                            <div class="cards">
                                <div class="box card card-total" @click='lnkSales("?stage=wins")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                            <h4>{{dataDash.wins.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card card-total">
                                    <div class="card-content" @click='lnkSales("?stage=wins")'>
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                            <h4>{{dataDash.wins.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card">
                                    <div class="card-content" @click='lnkSales("?stage=wins")'>
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                            <h4>{{dataDash.wins.packages}} / {{dataDash.totalPackages}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card" @click='lnkLeads("?stage=wins")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions-leads")}}</p>
                                            <h4>{{dataDash.wins.leads}} / {{dataDash.totalLeads}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- SALES PROGRESS -->
                        <div class="group-cards group-progress">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-in-progress")}}</div>

                            <div class="cards">
                                <div class="box card card-total" @click='lnkSales("?stage=draw")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                            <h4>{{dataDash.draw.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card card-total" @click='lnkSales("?stage=draw")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                            <h4>{{dataDash.draw.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card" @click='lnkSales("?stage=draw")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                            <h4>{{dataDash.draw.packages}} / {{dataDash.totalPackages}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card" @click='lnkLeads("?stage=draw")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.conversions-leads")}}</p>
                                            <h4>{{dataDash.draw.leads}} / {{dataDash.totalLeads}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- COMMISSIONS -->
                        <div class="group-cards group-commissions">
                            <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.commissions")}}</div>

                            <div class="cards">
                                <!-- <div class="box card card-total" @click="modalWithdraw.modal = true"> -->
                                <div class="box card card-total">
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.available")}}</p>
                                            <h4>{{dataDash.commissions.available.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-withdraw.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card card-total" @click='lnkSales("?status=waitingConfirmation")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.waiting-confirmation")}}</p>
                                            <h4>{{dataDash.commissions.waitingConfirmation.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card" @click='lnkSales("?status=pendingApproval")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.pending-approval")}}</p>
                                            <h4>{{dataDash.commissions.pendingApproval.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-pending-approval.svg" class="icon">
                                    </div>
                                </div>

                                <div class="box card" @click='lnkSales("?status=received")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.received")}}</p>
                                            <h4>{{dataDash.commissions.received.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-received.svg" class="icon">
                                    </div>
                                </div>

                                <!-- <div class="box card card-total" @click='lnkSales("?status=waitingPayment")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.waiting-payment")}}</p>
                                            <h4>{{dataDash.commissions.waitingPayment.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-waiting.svg" class="icon">
                                    </div>
                                </div> -->

                                <!-- <div class="box card" @click='lnkSales("?stage=loss")'>
                                    <div class="card-content">
                                        <div>
                                            <p>{{getMyLanguage("dashboard","card-dash.cancelled")}}</p>
                                            <h4>{{dataDash.commissions.cancelled.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                        </div>
                                        <img src="~@/assets/images/icons/dash-card-cancelled.svg" class="icon">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>


                    <!-- Chart -->
                    <div class="box Chart" v-if="showContent && showCart">
                        <Chart :options="dataChart" />
                    </div>


                    
                </div>
            </ion-content>


            <!-- MODAL WITHDRAW -->
            <div class="modal" v-if="modalWithdraw.modal">
                <div @click="(modalWithdraw.modal = false),(modalWithdraw.select = true),(modalWithdraw.finish = false),(modalWithdraw.success = false),(modalWithdraw.unavailable = false)" class="close-modal"></div>
                <div class="box cont-modal">

                    <!-- WITHDRAW SELECT -->
                    <div class="withdraw-select" v-if="modalWithdraw.select">
                        <h3>{{ getMyLanguage("dashboard", "card-dash.withdraw") }} select</h3>

                        <div class="list-withdraw">
                            <div class="scroll-withdraw scroll-vertical">
                                <div class="form-group-check">
                                    <input type="checkbox"  id="12151215" class="form-check">
                                    <label for="12151215" class="form-check-label">
                                        <strong>125,50 €</strong>
                                        <small>de Francisco Mendes em 20/01</small>
                                    </label>
                                </div>

                                <div class="form-group-check">
                                    <input type="checkbox" id="21212121" class="form-check">
                                    <label for="21212121" class="form-check-label">
                                        <strong>230,00 €</strong>
                                        <small>de Francisco Vieira em 20/01</small>
                                    </label>
                                </div>

                                <ion-button @click="(modalWithdraw.select = false),(modalWithdraw.finish = true)" v-if="!withdrawLoad" expand="block" class="btnPrimary">Continue</ion-button>
                                
                                <div class="btn-primary btn-loading" v-if="withdrawLoad"></div>
                            </div>
                        </div>
                    </div>
                    

                    <!-- WITHDRAW FINISH -->
                    <div class="scroll-vertical withdraw-finish" v-if="modalWithdraw.finish">
                        <h3>{{ getMyLanguage("dashboard", "card-dash.withdraw") }}</h3>

                        <div class="twoColumns">
                            <label>
                                {{ getMyLanguage("dashboard", "modal.withdraw.value") }}
                                <div class="total-withdraw">355,50 €</div>
                            </label>
                            
                            <label>
                                {{ getMyLanguage("dashboard", "modal.withdraw.bankAccount") }}
                                <select class="form">
                                    <option value="santander">Santander (1251 / 35121-6)</option>
                                </select>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" v-model="acceptWithdraw" name="" id="acceptWithdraw" class="form-check">
                            <label for="acceptWithdraw" class="form-check-label">
                                <small>{{ getMyLanguage("dashboard", "modal.withdraw.acceptWithdraw") }}</small>
                            </label>

                            <ion-button v-if="!acceptWithdraw" expand="block" class="btnPrimary disabled">{{ getMyLanguage("dashboard", "card-dash.withdraw") }}</ion-button>
                            <ion-button @click="validateWithdraw()" v-if="acceptWithdraw && !withdrawLoad" expand="block" class="btnPrimary">{{ getMyLanguage("dashboard", "card-dash.withdraw") }}</ion-button>
                            <div class="btn-primary btn-loading" v-if="withdrawLoad"></div>
                        </div>
                    </div>


                    <!-- SUCCESS -->
                    <div class="scroll-vertical" v-if="modalWithdraw.success">
                        <h3>{{ getMyLanguage("dashboard", "modal.withdraw.success") }}</h3>

                        <div class="box success-withdraw">
                            <h5>{{ getMyLanguage("dashboard", "modal.withdraw.success.msg01") }}</h5>
                            <p>{{ getMyLanguage("dashboard", "modal.withdraw.success.msg02") }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="modalWithdraw.modal"></div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, IonButton} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import api from '@/config/api.js'
    import moment from 'moment';

    import { Chart } from 'highcharts-vue'

    export default {
        components: {
            IonApp, IonPage, IonContent, IonButton,
            Header,
            Footer,
            Chart
        },
        data () {
            return {
                load: true,
                showCart: false,
                
                flagSelected: {},
                showContent: false,
                dataDash: {},

                errorsGet: {
                    dataDash: false
                },
                
                modalWithdraw: {
                    modal: false,
                    select: true,
                    finish: false,
                    success: false,
                    unavailable: false
                },
                acceptWithdraw: false,
                withdrawLoad: false,
                successWithdraw: false,

                dataChart: {}
            }
        },
        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).format('DD/MM');
            },
            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            validateWithdraw(){
                this.modalWithdraw.finish = false
                this.modalWithdraw.success = true

                setTimeout(() => {
                    this.successWithdraw = true
                }, 2000);
            },

            lnkSales(value){
                window.location.href = this.flagSelected + '/sales' + value
            },

            lnkLeads(value){
                window.location.href = this.flagSelected + '/leads' + value
            }
        },


        async mounted () {
            this.flagUrl();

            if ( this.$route.params.registerId === undefined ){
                await api.get('/api/v1/reports/commissions/agent/dashboard')
                .then(response => {
                    this.dataDash = response.data
                })
                .catch(error => {
                    this.errorsGet.dataDash = error
                    this.load = false
                })
            
            } else{
                await api.get('/api/v1/reports/commissions/agent/dashboard/' + this.$route.params.registerId)
                .then(response => {
                    this.dataDash = response.data
                })
                .catch(error => {
                    this.errorsGet.dataDash = error
                    this.load = false
                })
            }
            
            const resumeChart = await api.get('/api/v1/reports/commissions/agent/weekly')
            this.dataChart = resumeChart.data

            setTimeout(() => {
                this.showContent = true
                this.load = false
            }, 1500);
        }
    }
</script>


<style scoped>
    .title-secondary{margin-bottom: 30px;}
    .title-secondary small{display: block; margin: 3px 0 10px;}
    .box h3{font-size: 20px; color: var(--color-primary); margin-bottom: 20px;}
    .box h3:after{content: ''; display: block; margin-top: 5px; width: 40px; height: 3px; background: var(--color-quaternary);}

    /* Chart */
    .box.Chart{margin-bottom: 50px;}
    .box.Chart img{display: none;}
    
    @media (max-width: 576px){.box.Chart .Chart-mobile{display: block;}}
    @media (min-width: 576px) and (max-width: 992px){.box.Chart .Chart-tablet{display: block;}}
    @media (min-width: 992px){.box.Chart .Chart-desktop{display: block;}}
    
    
    /* CARDS */
    .cardsDash{margin-bottom: 50px; display: flex; flex-wrap: wrap; text-shadow: 1px 1px 2px rgba(0,0,0,.4);}
    /* .cardsDash .cards{display: flex; flex-wrap: wrap;} */
    /* .cardsDash .cards .card{margin: 6px; padding: 12px; width: calc(100% / 2 - 12px)} */
    .cardsDash .cards .card{border-radius: 0; margin-bottom: 0; padding: 12px; width: 100%; transition: 0.3s; cursor: pointer;}
    .cardsDash .cards .card:last-child{border-radius: 0 0 12px 12px;}
    .cardsDash .cards .card h2{font-family: var(--font-primary-semibold); color: var(--color-text-secondary); text-align: center; font-size: 14px; margin: 0;}
    
    .cardsDash .cards .card-content{display: flex; justify-content: space-between; align-items: center;}
    .cardsDash .cards .card-content p{font-size: 15px; line-height: 16px; margin-bottom: 6px; color: rgba(255,255,255,0.9);}
    .cardsDash .cards .card-content h4{font-family: var(--font-primary-semibold); font-size: 18px; line-height: 18px; color: var(--color-text-tertiary); margin: 0;}
    /* .cardsDash .cards .card-content .icon{display: block; width: 32px; height: 32px; border-radius: 50%; background-color: rgba(255,255,255,1);} */
    .cardsDash .cards .card-content .icon{display: block; width: 38px; height: 38px; padding: 7px; border-radius: 5px; margin-left: 12px; background-color: rgba(0,0,0,0.1);}

    
    /* GROUP CARDS */
    .cardsDash .group-cards{width: 100%; padding: 0 10px; margin-bottom: 30px;}
    .cardsDash .group-cards .header-group{font-family: var(--font-primary-semibold); font-size: 16px; line-height: 26px; color: var(--color-text-tertiary); padding: 12px; border-radius: 12px 12px 0 0;}


    .group-cards.group-wins .header-group{background-color: rgba(74,174,87,1);}
    .group-cards.group-wins .box.card:nth-of-type(1){background-color: rgba(74,174,87,.90);}
    .group-cards.group-wins .box.card:nth-of-type(2){background-color: rgba(74,174,87,.84);}
    .group-cards.group-wins .box.card:nth-of-type(3){background-color: rgba(74,174,87,.78);}
    .group-cards.group-wins .box.card:nth-of-type(4){background-color: rgba(74,174,87,.72);}
    .group-cards.group-wins .box.card:nth-of-type(5){background-color: rgba(74,174,87,.66);}
    .group-cards.group-wins .box.card:nth-of-type(6){background-color: rgba(74,174,87,.60);}
    .group-cards.group-wins .box.card:nth-of-type(7){background-color: rgba(74,174,87,.54);}

    .group-cards.group-progress .header-group{background-color: rgba(235,104,52,1);}
    .group-cards.group-progress .box.card:nth-of-type(1){background-color: rgba(235,104,52,.90);}
    .group-cards.group-progress .box.card:nth-of-type(2){background-color: rgba(235,104,52,.84);}
    .group-cards.group-progress .box.card:nth-of-type(3){background-color: rgba(235,104,52,.78);}
    .group-cards.group-progress .box.card:nth-of-type(4){background-color: rgba(235,104,52,.72);}
    .group-cards.group-progress .box.card:nth-of-type(5){background-color: rgba(235,104,52,.66);}
    .group-cards.group-progress .box.card:nth-of-type(6){background-color: rgba(235,104,52,.60);}
    .group-cards.group-progress .box.card:nth-of-type(7){background-color: rgba(235,104,52,.54);}

    .group-cards.group-commissions .header-group{background-color: rgba(31,53,97,1);}
    .group-cards.group-commissions .box.card:nth-of-type(1){background-color: rgba(31,53,97,.90);}
    .group-cards.group-commissions .box.card:nth-of-type(2){background-color: rgba(31,53,97,.84);}
    .group-cards.group-commissions .box.card:nth-of-type(3){background-color: rgba(31,53,97,.78);}
    .group-cards.group-commissions .box.card:nth-of-type(4){background-color: rgba(31,53,97,.72);}
    .group-cards.group-commissions .box.card:nth-of-type(5){background-color: rgba(31,53,97,.66);}
    .group-cards.group-commissions .box.card:nth-of-type(6){background-color: rgba(31,53,97,.60);}
    .group-cards.group-commissions .box.card:nth-of-type(7){background-color: rgba(31,53,97,.54);}


    @media (min-width: 768px){
        .cardsDash{flex-wrap: nowrap;}
        .cardsDash .group-cards{width: 33.33%;}
        .cardsDash .cards .card{padding: 18px 12px;}
        /* .group-cards .cards .box.card.card-total{padding: 27px 12px}
        .group-cards .cards .box.card.card-total .card-content p{font-size: 18px; line-height: 22px; margin-bottom: 9px;}
        .group-cards .cards .box.card.card-total .card-content h4{font-size: 22px; line-height: 26px;} */
        /* .group-cards.group-cards.group-commissions .cards .box.card{padding: 12px} */
    }

    @media (min-width: 768px) and (max-width: 992px){
        
    }
    
    @media (min-width: 768px) and (max-width: 992px){
        .group-cards .cards .box.card.card-total{padding: 30px 12px}
        .group-cards .cards .box.card.card-total .card-content p{font-size: 16px; line-height: 20px; margin-bottom: 9px;}
        .group-cards .cards .box.card.card-total .card-content h4{font-size: 18px; line-height: 22px;}
        
        .cardsDash .cards .card-content p{font-size: 12px;}
        .cardsDash .cards .card-content h4{font-size: 14px;}
    }


    

    .box.list-result{margin-bottom: 50px;}
    .list-result{border-spacing: 0; border-collapse: separate;}
    .list-result tbody tr{cursor: pointer;}
    .list-result tr.list td:first-child{border-radius: 10px 0 0 12px;}
    .list-result tr.list td:last-child{border-radius: 0 10px 12px 0;}
    
    .list-result tr.list td{border-top: 2px solid #fff; border-bottom: 2px solid #fff;}

    .purchase-history-header tr{background: #eee;}
    .purchase-history-header tr th{color: var(--color-primary); font-size: 12px; white-space: nowrap; text-align: center; border-bottom: 6px solid #fff;}
    .purchase-history-header tr th.fullName{text-align: left;}
    .purchase-history-header tr th.fiat{text-align: right;}
    .purchase-history-header tr th.fiat .short{display: none;}
    

    .modal .btnPrimary {margin-top: 40px;}

    .twoColumns{display: flex; width: 100%; text-align: left;}
    .twoColumns label{width: 50%;}
    .twoColumns label:nth-of-type(1){padding-right: 5px;}
    .twoColumns label:nth-of-type(2){padding-left: 5px;}
    
    @media (max-width: 576px){
        .modal .twoColumns{flex-direction: column;}
        .modal .twoColumns label{width: 100%; padding: 0;}
    }

    @media (min-width: 768px){
        .twoTables{display: flex;}
        .twoTables .box-leads-progress{width: 45%; margin-right: 20px;}
        .twoTables .box-sales-progress{width: 55%;}
    }


    .box.success-withdraw{background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; border-radius: 12px; margin: 40px 0 10px 0;}
    .box.success-withdraw h5{color: var(--color-text-tertiary); margin-bottom: 20px;}
    .box.success-withdraw p{margin-bottom: 0; color: var(--color-text-tertiary);}

    .list-withdraw .form-group-check label{display: flex; align-items: center; margin-bottom: 10px;}
    .list-withdraw .form-group-check label strong{line-height: 16px; margin-right: 15px;}
    .list-withdraw .form-group-check label small{line-height: 16px; color: #999}

    .total-withdraw{font-family: var(--font-primary-semibold); font-size: 16px; line-height: 48px;}


    .cards-dash{display: flex; align-items: flex-start;}
    .cards-dash h2{font-family: var(--font-primary-semibold); color: var(--color-primary); font-size: 12px; margin: 0 0 3px 0;}
    .cards-dash .card-content{display: flex; justify-content: center; align-items: center; flex-direction: column; margin: 0 15px 20px 0; background: var(--color-text-tertiary); border-radius: 12px; padding: 20px 15px; min-width: 120px; height: 80px;}
    .cards-dash .card-content h4{font-family: var(--font-primary-semibold); color: var(--color-secondary); font-size: 16px; margin: 0 0 3px 0;}
    .cards-dash .card-content p{margin: 0; font-size: 12px; color: #666666;}


    @media (max-width: 768px){
        .latest-sales .list-result .campaign,
        .latest-sales .list-result .method,
        .latest-sales .list-result .statusPayment{display: none !important;}

        .list-result .list span{font-size: 10px !important;}

        .list-result .purchase-history-header tr th{font-size: 10px !important;}
        .list-result .purchase-history-header tr th.fiat .full{display: none;}
        .list-result .purchase-history-header tr th.fiat .short{display: block; text-align: center;}

        .list-result .list span{display: block; line-height: 13px;}
    }
</style>