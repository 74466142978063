<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>

                <h3 class="title-secondary" v-if="!filter">
                    {{ getMyLanguage("sales", "sales.title") }}
                    <small v-if="nameAgentFilter">{{nameAgentFilter}}</small>
                </h3>

                <div class="box-error" v-if="errorsGet.dataSales != false">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataSales}}
                    </div>
                </div>


                <div class="content ion-padding" v-if="!errorsGet.dataSales">
                    
                    <div class="load load-center" v-if="lengthSales === 'load'"></div>
                    
                    <div class="search-bar" v-if="lengthSales === true && !filter">
                        <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("sales","sales.search")'>
                        <div class="btn-filter" @click="filter = true"></div>
                        <div class="btn-back" v-if="nameAgentFilter" onclick="history.back()"></div>
                    </div>

                    <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                    <div class="filter box" v-if="filter">
                        <div class="close-filter" @click="filter = false"></div>
                        
                        <div class="filters">
                            <div class="form-group-switch">
                                <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                                <label for="stage" class="form-switch-label">
                                    <small>Filter name</small>
                                </label>
                            </div>
                        </div>

                        <div class="btn-primary">Filter</div>
                    </div>


                    <div class="box" v-if="lengthSales === true">
                        <!-- {{dataSales[0]}} -->
                        <div class="scroll-horizontal">
                            <table class="list-result">
                                <thead class="purchase-history-header">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                        <th class="fullName no-wrap text-left">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","agent") }}</th>
                                        <th class="campaign">{{ getMyLanguage("list-result","phase") }}</th>
                                        <th class="method">{{ getMyLanguage("list-result","payment") }}</th>
                                        <th class="statusPayment">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="fiat">
                                            <span class="full">{{ getMyLanguage("list-result","salePrice") }}</span>
                                            <span class="short">{{ getMyLanguage("list-result","sale") }}</span>
                                        </th>
                                        <!-- <th><span class="full">{{ getMyLanguage("list-result","commission") }}</span></th> -->
                                        <th class="fiat">
                                            <span class="full">{{ getMyLanguage("list-result","commission") }}</span>
                                            <span class="short">{{ getMyLanguage("list-result","percent") }}</span>
                                        </th>
                                        <!-- <th class="fiat">
                                            <span class="full">{{ getMyLanguage("list-result","payday") }}</span>
                                            <span class="short"></span>
                                        </th> -->
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr v-for="(salesList,index) in dataSales" :key="index" @click="viewSale(index)" class="list">
                                        <!-- <td class="date"><span v-html="dateTime(salesList.sale_date)"></span></td> -->
                                        <td class="date"><span>{{dateTime(salesList.sale.sale_date)}}</span></td>
                                        <td class="fullName no-wrap text-left"><span>{{salesList.buyer.profile_name}}</span></td>
                                        <td class="fullName text-left"><span>{{salesList.agent.agent_full_name}}</span></td>
                                        <td class="campaign"><span>{{ getMyLanguage("status.short", salesList.campaign.campaign_name) }}</span></td>
                                        <td class="method" :class="salesList.payment.payment_type"><span>{{ getMyLanguage("status", salesList.payment.payment_type) }}</span></td>
                                        <td class="statusPayment" :class="salesList.payment.payment_status"><span>{{ getMyLanguage("status", salesList.payment.payment_status) }}</span></td>
                                        <td class="fiat"><span>{{salesList.sale.sale_final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td class="fiat"><span>{{salesList.commission.commission_amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <!-- <td class="fiat"><span v-html="dateTime(salesList.commission.commission_pay_day)"></span></td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="no-results" v-if="lengthSales === false">
                        <p>{{ getMyLanguage("sales", "no-sales") }}</p>
                        <div class="btn-back" v-if="nameAgentFilter" onclick="history.back()"></div>
                    </div>
                </div>
            </ion-content>
            
            <!-- MODAL -->
            <div class="modal" v-if="modalShow">
                <div @click="modalShow = false" class="close-modal"></div>
                <div class="box cont-modal phase-modal">
                    <div class="scroll-vertical">
                        <h3>{{dataSalesShow.buyer.profile_name}}</h3>

                        <!-- Não há vendas realizadas ainda -->

                        <div class="sale-description" :class="dataSalesShow.status">
                            <div class="fullField"><span>{{ getMyLanguage("modal", "modal.sale-description.id") }}:</span> <strong>#{{dataSalesShow.sale.sale_reference}}</strong></div>
                            <div class="fullField"><span>{{ getMyLanguage("modal", "modal.sale-description.date") }}:</span> <strong v-html="dateTimeFull(dataSalesShow.sale.sale_date)"></strong></div>
                            <div class="fullField"><span>{{ getMyLanguage("modal", "modal.sale-description.payment-method") }}:</span> <strong>{{dataSalesShow.payment.payment_type}}</strong></div>
                            <div class="fullField divider"><span>{{ getMyLanguage("modal", "modal.sale-description.status") }}:</span> <strong class="statusModal" :class="dataSalesShow.payment.payment_status">{{ getMyLanguage("status", dataSalesShow.payment.payment_status) }}</strong></div>

                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.product") }}:</span> <strong>{{dataSalesShow.product.product_name}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.campaign") }}:</span> <strong>{{dataSalesShow.campaign.campaign_name}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.quantity") }}:</span> <strong>{{dataSalesShow.sale.sale_quantity}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.original-price") }}:</span> <strong>{{dataSalesShow.sale.sale_original_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="fiat"></span></strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.discount") }}:</span> <strong>{{dataSalesShow.campaign.campaign_discount}}%</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.bonus") }}:</span> <strong>{{dataSalesShow.campaign.campaign_bonus}}%</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.sale-description.fee") }}:</span> <strong>{{dataSalesShow.sale.sale_fee}}%</strong></div>
                            <div class="finalPrice"><span>{{ getMyLanguage("modal", "modal.sale-description.final-price") }}:</span> <strong>{{dataSalesShow.sale.sale_final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="fiat"></span></strong></div>
                            <div class="finalPrice"><span>{{ getMyLanguage("modal", "modal.sale-description.commission") }}:</span> <strong>{{dataSalesShow.commission.commission_amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="fiat"></span></strong></div>
                            <!-- <div><span>{{ getMyLanguage("modal", "modal.sale-description.payDay") }}:</span> <strong v-html="dateTimeFull(dataSalesShow.commission.commission_pay_day)"></strong></div> -->
                        </div>

                        <!-- <div class="btn-primary" @click="newSale(dataSalesShow.id)">{{ getMyLanguage("modal", "modal.sale-description.new-sale") }}</div> -->
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="this.modalShow === true"></div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import moment from 'moment';
    import api from '@/config/api.js'

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                lengthSales: 'load',
                dataSales: [],
                dataSalesShow: [],
                flagSelected: {},
                filter: false,
                filtersParam: {},
                filtersCheck: {},
                modalShow: false,
                nameAgentFilter: false,

                errorsGet: {
                    dataSales: false
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            dateTime(value) {
                return moment(value).format('DD/MM/YY');
            },
            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },


            viewSale(value){
                this.dataSalesShow = this.dataSales[value]
                this.modalShow = true
            },

            async openToastError(errorMsg) {
                const toast = await toastController
                    .create({
                    message: errorMsg,
                    color: "danger",
                    duration: 10000
                    })
                return toast.present();
            }
        },

        async mounted () {
            this.flagUrl();

            const urlParams = new URLSearchParams(window.location.search);
            const accountIdParams = urlParams.get('account_id');
            const stageParams = urlParams.get('stage');
            const nameLead = urlParams.get('name')
            this.nameAgentFilter = nameLead

            if ( accountIdParams != null){
                api.get('/api/v1/sales/commissions?account_id=' + accountIdParams)
                .then(response => {
                    this.dataSales = response.data

                    if ( this.dataSales.length > 0 ){
                        this.lengthSales = true
                    } else{
                        this.lengthSales = false
                    }

                })
                .catch(error => {
                    this.errorsGet.dataSales = error.response.status
                })

            } else if ( stageParams != null ){    
                api.get('/api/v1/sales/commissions?stage=' + stageParams)
                .then(response => {
                    this.dataSales = response.data

                    if ( this.dataSales.length > 0 ){
                        this.lengthSales = true
                    } else{
                        this.lengthSales = false
                    }

                })
                .catch(error => {
                    this.errorsGet.dataSales = error.response.status
                })
            } else{
                api.get('/api/v1/sales/commissions')
                .then(response => {
                    this.dataSales = response.data

                    if ( this.dataSales.length > 0 ){
                        this.lengthSales = true
                    } else{
                        this.lengthSales = false
                    }
                })
                .catch(error => {
                    this.errorsGet.dataSales = error.response.status
                })
            }   
        }
    }
</script>


<style scoped>
    .load{width: 40px; height: 40px; margin: 0 auto 25px; background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;}
    .load.load-center{width: 80px; height: 80px; position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); margin: 0;}

    .list-result .list .date{padding-left: 10px;}

    .purchase-history-header tr{background: #eee;}
    .purchase-history-header tr th{color: var(--color-primary); font-size: 12px; white-space: nowrap; border-bottom: 6px solid #fff;}
    .purchase-history-header tr th.fiat{text-align: right;}
    .purchase-history-header tr th.fiat .short{display: none;}

    th.fullName, td.fullName{text-align: left; padding-left: 20px;}

    .fullName{width: auto !important;}
    .fullName + .fullName{width: 100% !important;}


    @media (max-width: 576px){
        .sale-description div{width: 100%;}
        th.fullName, td.fullName{padding-left: 5px;}
    }

    @media (max-width: 768px){
        .list-result .campaign,
        .list-result .method,
        .list-result .statusPayment{display: none !important;}

        .list-result .purchase-history-header tr th.fiat .full{display: none;}
        .list-result .purchase-history-header tr th.fiat .short{display: block; text-align: center;}

        .list-result .list span{display: block; font-size: 12px; line-height: 13px;}
    }

    @media (max-width: 992px){
        .fullName{width: 100% !important;}
        .fullName + .fullName{display: none !important;}
    }
</style>