<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>

                <h3 class="title-secondary" v-if="!filter">
                    {{ getMyLanguage("leads", "leads.title") }}
                    <small v-if="nameLeadFilter">{{nameLeadFilter}}</small>
                </h3>

                <div class="box-error" v-if="errorsGet.dataLeads != false">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataLeads}}
                    </div>
                </div>


                <div class="content ion-padding" v-if="!errorsGet.dataLeads">

                    <div class="load-green" v-if="loading"><span></span></div>

                    <div class="search-bar" v-if="!loading && lengthLeads && !filter">
                        <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("leads", "leads.search")'>
                        <div class="btn-filter" @click="filter = true"></div>
                        <div class="btn-back" v-if="nameLeadFilter" onclick="history.back()"></div>
                    </div>

                    
                    <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                    <div class="filter box" v-if="filter">
                        <div class="close-filter" @click="filter = false"></div>

                        <div class="filters">
                            <div class="form-group-switch">
                                <input type="checkbox" v-model="acceptWithdraw" name="" id="acceptWithdraw" class="form-switch">
                                <label for="acceptWithdraw" class="form-switch-label">
                                    <small>Filter name</small>
                                </label>
                            </div>
                        </div>

                        <div class="btn-primary">Filter</div>
                    </div>

                    <div class="box" v-if="!loading && lengthLeads">
                        <div class="scroll-horizontal">
                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th>{{ getMyLanguage("list-result","purchase") }}</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <!-- <th class="type">{{ getMyLanguage("list-result","type") }}</th>
                                        <th class="temperature">{{ getMyLanguage("list-result","temperature") }}</th> -->
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr v-for="(clientList,index) in dataLeads" :key="index" class="list">
                                        <td class="date" @click="modalLead(index)"><span>{{ dateTimeMin(clientList.account_created_at) }}</span></td>
                                        <td class="fullName" @click="modalLead(index)"><span>{{clientList.profile_full_name}}</span></td>
                                        <td class="status tooltip" :class="clientList.account_status">
                                            <span>{{ getMyLanguage("status", clientList.account_status) }}</span>

                                            <div class="tooltip">{{ getMyLanguage("leads","tooltip." + clientList.account_status) }}</div>
                                        </td>
                                        <td class="purchase" @click="modalLead(index)">
                                            <!-- <span v-if="clientList.has_purchase" @click="modalSale(clientList.account_id)"><img src="~@/assets/images/icons/has-purchase.svg"></span> -->
                                            <span v-if="clientList.has_purchase"><img src="~@/assets/images/icons/has-purchase.svg"></span>
                                            <small v-if="!clientList.has_purchase">- -</small>
                                        </td>
                                        <td class="sales">
                                            <span @click="listSales(clientList.account_id, clientList.profile_full_name)" v-if="clientList.is_agent">{{ getMyLanguage("leads","list.sales") }}</span>
                                            <small v-if="!clientList.is_agent">- -</small>
                                        </td>
                                        <td class="leads">
                                            <span @click="listLeads(clientList.account_id, clientList.profile_full_name)" v-if="clientList.is_agent">Leads</span>
                                            <small v-if="!clientList.is_agent">- -</small>
                                        </td>
                                        <td class="recruit">
                                            <span @click="openRecruit(index)" v-if='!clientList.is_agent && clientList.account_status === "Verified" && clientList.account_id != dataAgent.accountId'>{{ getMyLanguage("register", "agent.label.recruit") }}</span>
                                            <small v-if="clientList.is_agent">- -</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="no-results" v-if="!loading && !lengthLeads">
                        <p>{{ getMyLanguage("leads", "no-leads") }}</p>
                        <div class="btn-back" v-if="nameLeadFilter" onclick="history.back()"></div>
                    </div>
                </div>
            </ion-content>



            <!-- MODAL SALE -->
            <!-- <div class="modal modal-sale" v-if="showModalSale">
                <div @click="showModalSale = false, showSales = false" class="close-modal"></div>
                <div class="box cont-modal">
                    <div class="scroll-vertical" v-if="!dataFollowUpModalSuccess">
                        <h3 class="title-secondary">{{dataModal.profile_full_name}}</h3>

                        <div class="load-green" v-if="!showSales"></div>

                        <div v-if="showSales">
                            {{dataSales}}
                        </div>
                    </div>
                </div>
            </div> -->



            <!-- MODAL LEAD -->
            <div class="modal modal-lead" v-if="showModalLead">
                <div @click="showModalLead = false, showFollowUp = false" class="close-modal"></div>
                <div class="box cont-modal">
                    <div class="scroll-vertical" v-if="!dataFollowUpModalSuccess">
                        <h3 class="title-secondary">{{dataModal.profile_full_name}}</h3>

                        <div class="load-green" v-if="!showFollowUp"></div>

                        <div v-if="showFollowUp">
                            <label>
                                {{ getMyLanguage("register", "register.label.lead-type") }}
                                <select class="form" v-model="dataFollowUpModal.leadType">
                                    <option v-for="(leadType,index) in dataLeadType" :key="index" :value="leadType.id">{{ getMyLanguage("status", leadType.value) }}</option>
                                </select>
                            </label>

                            <div class="twoColumns">
                                <label>
                                    {{ getMyLanguage("register", "register.label.campaign") }}
                                    <select class="form" v-model="dataFollowUpModal.campaignId">
                                        <option v-for="(campaign,index) in dataCampaign" :key="index" :value="campaign.id">{{ getMyLanguage("status", campaign.name) }}</option>
                                    </select>
                                </label>

                                <label>
                                    <span>{{ getMyLanguage("register", "register.label.date-return") }}</span>
                                    <input type="date" class="form" v-model="dataFollowUpModal.returnAt">
                                </label>
                            </div>

                            <div class="level-lead">
                                <label>
                                    {{ getMyLanguage("register", "register.label.lead-level") }}
                                </label>
                                
                                <div class="level-lead-content">
                                    <div class="form-group-radio" :class="temperature.value" v-for="(temperature,index) in dataLeadTemperature" :key="index">
                                        <input type="radio" :value="dataFollowUpModal.leadTemperature" name="level-lead" :id="temperature.id" class="form-radio" @click='dataFollowUpModal.leadTemperature = temperature.id' :checked="(dataFollowUpModal.leadTemperature == temperature.id)" >
                                        <label :for="temperature.id" class="form-radio-label">
                                            <small>{{ getMyLanguage("status", temperature.value) }}</small>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <label>
                                {{ getMyLanguage("register", "register.label.observations") }}
                                <div class="textArea">
                                    <textarea class="form" v-model="dataFollowUpModal.observations" spellcheck="false" autocapitalize="off"></textarea>
                                </div>
                            </label>

                            <div class="item-buttons" v-if="!stepRegister.load">
                                <!-- <div class="recruit" v-if='!dataModal.is_agent && dataModal.account_status === "Verified" && dataModal.account_id != dataAgent.accountId'>
                                    <div class="btn-recruit" @click="openRecruit()">{{ getMyLanguage("register", "agent.label.recruit") }}</div>
                                </div> -->

                                <div @click="saveFollowUp()" expand="block" class="btn-primary">{{ getMyLanguage("register", "register.btn-save") }}</div>
                            </div>

                            <div class="btn-primary btn-loading" v-if="stepRegister.load"></div>
                        </div>
                    </div>


                    <!-- SUCCESS -->
                    <div v-if="dataFollowUpModalSuccess">
                        <h3 class="title-secondary">{{dataFollowUpModal.account.name}} {{dataFollowUpModal.account.surname}}</h3>

                        <div class="success">
                            <h2>{{ getMyLanguage("register", "success.txt") }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="showModalLead"></div>




            <!-- MODAL RECRUIT -->
            <div class="modal modal-recruit" v-if="showModalRecruit">
                <div @click="showModalRecruit = false, loadingRecruit = false" class="close-modal"></div>
                <div class="box cont-modal">
                    <div class="scroll-vertical">
                        <h3 class="title-secondary" v-if="!dataRecruitModalSuccess">{{ getMyLanguage("register", "agent.recruit.title") }}</h3>

                        <div class="load-blue" v-if="loadingRecruit"><span></span></div>

                        <!-- HAS INVITE -->
                        <div class="box has-invite" v-if="!loadingRecruit && !dataRecruitModalSuccess">
                            <h4 class="title-secondary">{{dataModal.profile_full_name}}</h4>
                            
                            <div class="twoColumns" v-if="!dataRecruit.hasInvite">
                                <label>
                                    {{ getMyLanguage("register", "agent.label.salesRegion") }}
                                    <select class="form" v-model="dataRecruit.regionId">
                                        <option value="72ded956-b703-477c-bf4b-1d8566618d42">Global Region</option>
                                        <option value="53d079c2-3101-4757-b605-18dd0cfe75fb">Portugal</option>
                                        <option value="c621dd7e-b647-428b-888b-934531285ad2">Portugal Norte</option>
                                    </select>
                                </label>

                                <label>
                                    {{ getMyLanguage("register", "agent.label.agentType") }}
                                    <select class="form" v-model="dataRecruit.agentTypeId">
                                        <option value="ce930f11-b951-4382-a784-fd495b2ad487">{{ getMyLanguage("register", "agent.select.fieldSeller") }}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="recruitment-progress" v-if="dataRecruit.hasInvite">
                                {{getMyLanguage("register","register.recruitment-progress")}}
                            </div>

                            <div @click="sendRecruit()" expand="block" class="btn-primary" v-if="!dataRecruit.hasInvite">{{ getMyLanguage("register", "agent.label.recruit") }}</div>
                            <!-- <div @click="sendRecruit()" expand="block" class="btn-primary" v-if="dataRecruit.hasInvite">{{ getMyLanguage("register", "agent.label.resend-recruitment") }}</div> -->
                            <div class="btn-primary btn-loading" v-if="stepRegister.load"></div>
                            

                            <div class="share-has-invite" v-if="dataRecruit.hasInvite">
                                <div class="has-invite-msg">{{ getMyLanguage("register","register.share.msg") }}</div>

                                <label>
                                    <!-- {{ getMyLanguage("register", "register.label.share") }} -->
                                    <div class="share" @click="copyShare(dataRecruit.inviteLink)"><span>{{dataRecruit.inviteLink}}</span></div>
                                </label>
                            </div>
                        </div>


                        <!-- SUCCESS -->
                        <div v-if="dataRecruitModalSuccess">
                            <h3 class="title-secondary">{{ getMyLanguage("register", "agent.successfull.title") }}</h3>

                            <div class="success-recruit">
                                <div class="resume-recruit">
                                    <p>{{ getMyLanguage("register", "agent.successfull.msg01") }} <strong>{{dataRecruit.fullName}}</strong> {{ getMyLanguage("register", "agent.successfull.msg02") }} <strong>{{ getMyLanguage("register", "agent.select.fieldSeller") }}</strong> {{ getMyLanguage("register", "agent.successfull.msg03") }} <strong>{{dataRecruit.regionName}}</strong>.</p>
                                    <p>{{ getMyLanguage("register", "agent.successfull.msg04") }}</p>
                                    
                                </div>
                                
                                <div class="success-share">
                                    <p>{{ getMyLanguage("register","register.share.msg") }}</p>
                                    <div class="share" @click="copyShare(dataRecruit.inviteLink)"><span>{{dataRecruit.inviteLink}}</span></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="showModalRecruit"></div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import api from '@/config/api.js'
    import moment from 'moment';
    import {mask} from 'vue-the-mask'

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        directives: {mask},
        data () {
            return {
                dataAgent: [],
                dataLeads: [],
                dataSales: [],

                lengthLeads: false,

                nameLeadFilter: false,

                flagSelected: {},
                loading: true,
                filter: false,

                filtersParam: {},
                
                // showModalSale: false,
                // showSales: false,

                showModalLead: false,
                dataModal: [],
                dataFollowUpModal: [],
                stepRegister: {
                    load: false
                },

                showFollowUp: false,

                dataFollowUpModalSuccess: false,

                showModalRecruit: false,
                loadingRecruit: true,
                dataRecruit: {},
                dataRecruitModalSuccess: false,

                errorsGet: {
                    dataLeads: false
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).format('YYYY-MM-DD');
            },
            dateTimeMin(value) {
                return moment(value).format('DD/MM/YY');
            },
            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            editLead(id){
                const idLead = id
                const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = '/' + languageRedirect + '/leads/' + idLead
            },

            newSale(id){
                const idLead = id
                const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = '/' + languageRedirect + '/leads/sale/' + idLead
            },


            async modalLead(id){
                this.dataModal = this.dataLeads[id]

                const resumeFollowUp = await api.get('/api/v1/sales/follow_up/' + this.dataModal.account_id)
                this.dataFollowUpModal = resumeFollowUp.data

                this.dataFollowUpModal.returnAt = this.dateTime(this.dataFollowUpModal.returnAt)
                this.dataFollowUpModalSuccess = false
                this.stepRegister.load = false

                this.showModalLead = true

                setTimeout(() => {
                    this.showFollowUp = true
                }, 500);
            },
            
            
            // async modalSale(id){
            //     this.showModalSale = true

            //     const resumeSales = await api.get('/api/v1/sales/commissions?account_id=' + id)
            //     this.dataSales = resumeSales.data

            //     console.log(id)

            //     setTimeout(() => {
            //         this.showSales = true
            //     }, 500);
            // },

            listLeads(value, name){
                window.location.href = '/' + this.flagSelected + '/leads?account_id=' + value + "&name=" + name
            },
            
            listSales(value, name){
                window.location.href = '/' + this.flagSelected + '/sales?account_id=' + value + "&name=" + name
            },

            async openRecruit(id){
                this.loadingRecruit = true
                this.dataModal = this.dataLeads[id]
                
                api.get('/api/v1/agent/recruit/' + this.dataModal.account_id)
                .then(response => {
                    this.dataRecruit = response.data

                    if (!this.dataRecruit.hasInvite){
                        this.dataRecruit.inviteId = "00000000-0000-0000-0000-000000000000"
                        this.dataRecruit.regionId = "72ded956-b703-477c-bf4b-1d8566618d42"
                        this.dataRecruit.agentTypeId = "ce930f11-b951-4382-a784-fd495b2ad487"
                    }

                    this.loadingRecruit = false
                })
                .catch(error => {
                    
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log(error.response.data)

                    this.stepRegister.load = false

                    this.$toast.error(error.response.data);

                    setTimeout(this.$toast.clear, 5000)

                    this.loadingRecruit = false
                })
                

                this.showModalLead = false
                this.showModalRecruit = true

            },


            async copyShare(urlShare) {
                try {
                await navigator.clipboard.writeText(urlShare);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("leads", "message-toast.copied"),
                        color: "secondary",
                        duration: 5000,
                    })
                return toast.present();
            },
            
            
            async openToastNocopy() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("leads", "message-toast.noCopied"),
                        color: "primary",
                        duration: 5000
                    })
                return toast.present();
            },


            saveFollowUp(){
                this.stepRegister.load = true

                api.post("/api/v1/sales/lead/" + this.dataFollowUpModal.account.accountId, this.dataFollowUpModal.followUp)
                .then(response => {
                    this.registerId = response.data.id;
                    this.dataFollowUpModalSuccess = true

                    setTimeout(() => {
                        this.$router.go()
                    }, 2000);
                })
                .catch(error => {
                    this.errorMessage = error.message;

                    this.stepRegister.load = false
                    this.dataFollowUpModalSuccess = false

                    this.openToastError(error);
                })
            },

            sendRecruit(){
                this.stepRegister.load = false
                const recruit = this.dataRecruit

                api.post("/api/v1/agent/recruit/" + this.dataModal.account_id, recruit)
                .then(response => {
                    this.registerId = response.data.id;
                    this.dataRecruit = response.data
                    this.dataRecruitModalSuccess = true
                    this.stepRegister.load = false

                })
                .catch(error => {
                    this.errorMessage = error.message;

                    this.stepRegister.load = false

                    this.openToastError(error);
                })
            },


            async openToastError(errorMsg) {
                const toast = await toastController
                    .create({
                    message: errorMsg,
                    color: "danger",
                    duration: 10000
                    })
                return toast.present();
            },
        },


        async mounted () {
            this.flagUrl();

            const urlParams = new URLSearchParams(window.location.search);
            const accountIdParams = urlParams.get('account_id');
            const nameLead = urlParams.get('name')
            this.nameLeadFilter = nameLead

            // LEADS
            if ( accountIdParams == null || accountIdParams == '' ){
                api.get('/api/v1/sales/profiles')
                .then(response => {
                    this.dataLeads = response.data

                    if ( this.dataLeads.length > 0 ){
                        this.lengthLeads = true
                    } else{
                        this.lengthLeads = false
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errorsGet.dataLeads = error.response.status
                })
            } else{
                api.get('/api/v1/sales/profiles?account_id=' + accountIdParams)
                .then(response => {
                    this.dataLeads = response.data

                    if ( this.dataLeads.length > 0 ){
                        this.lengthLeads = true
                    } else{
                        this.lengthLeads = false
                    }
                    
                    this.loading = false
                })
                .catch(error => {
                    this.errorsGet.dataLeads = error.response.status
                })
            }


            // AGENT
            api.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataAgent = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })


            // PHASE
            api.get('/api/v1/ico/phase')
            .then(response => {
                this.dataCampaign = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })


            // TEMPERATURE
            api.get('/api/v1/public/attributes/codes/lead_temperature')
            .then(response => {
                this.dataLeadTemperature = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })


            // LEAD TYPE
            api.get('/api/v1/public/attributes/codes/lead_type')
            .then(response => {
                this.dataLeadType = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })
            

            // LEAD STATUS
            // api.get('/api/v1/public/attributes/codes/lead_status')
            // .then(response => {
            //     this.dataLeadStatus = response.data
            // })
            // .catch(error => {
            //     this.openToastError(error);
            // })


            setTimeout(() => {
                this.loading = false
            }, 500);
        }
    }
</script>


<style scoped>
    /* .list-result{font-size: 14px;} */
    .bts-list{align-items: center;}    

    .list-result{border-spacing: 0; border-collapse: separate;}
    .list-result tbody tr{cursor: pointer;}
    .list-result tr.list td:first-child{border-radius: 10px 0 0 12px;}
    .list-result tr.list td:last-child{border-radius: 0 10px 12px 0;}

    .list-result .list td.purchase img{width: 20px}

    .list .lead-type{display: none;}
    .list .lead-temperature span{display: block; width: 100%; text-align: center; font-size: 12px; color: var(--color-text-tertiary); padding: 3px 5px; border-radius: 5px;}
    .list .lead-temperature.Cold span{background: rgba(31,53,97,0.7);}
    .list .lead-temperature.Warm span{background: var(--color-pending);}
    .list .lead-temperature.Hot span{background: var(--color-cancel);}

    .tooltip{position: relative;}
    .tooltip:hover .tooltip{display: block;}
    .tooltip .tooltip{display: none; position: absolute; z-index: 50; bottom: 30px; left: 50%; transform: translateX(-50%); width: 140px; line-height: 13px; font-size: 11px; padding: 5px 10px; border-radius: 6px; background: #fff; white-space: initial; -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5); -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5); box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5);}
    .tooltip .tooltip:after{content: ''; display: block; position: absolute; z-index: -1; left: 50%; bottom: -7px; transform: translateX(-50%); width: 0; height: 0; border-left: 7px solid transparent; border-right: 7px solid transparent; border-top: 7px solid #fff;}

    .tooltip.Verified .tooltip{color: var(--color-secondary);}
    .tooltip.PendingVerification .tooltip{color: var(--color-pending);}


    .fullName{padding-left: 10px;}

    @media (min-width: 576px){
        .list .lead-type{display: table-cell;}
        .list .lead-type span{display: block; width: 100%; text-align: center; white-space: nowrap; font-size: 12px; color: var(--color-text-secondary); background: var(--color-tertiary); padding: 3px 5px; border-radius: 5px;}

        .list .lead-temperature{display: table-cell;}

    }


    /* MODAL */
    .modal{width: 800px; max-width: 80%;}
    .twoColumns{display: flex; width: 100%; text-align: left;}
    .twoColumns label{width: 50%;}
    .twoColumns label:nth-of-type(1){padding-right: 5px;}
    .twoColumns label:nth-of-type(2){padding-left: 5px;}
    .twoColumns label span{display: block;}
    .scroll-vertical{max-height: 70vh;}

    .level-lead{margin-bottom: 30px;}
    .level-lead-content{display: flex; flex-wrap: wrap; padding: 5px 0; background: #f7f7f7; border-radius: 12px;}
    .level-lead-content .form-group-radio{margin: 5px 10px}

    .textArea{margin-bottom: 40px;}
    textarea.form{margin: 0; height: 110px;}
    
    .form-group-radio.Qualified .form-radio:checked+label:before{background-color: #4aae57; background-size: 12px auto !important;}
    .form-group-radio.InQualifying .form-radio:checked+label:before{background-color: #eb6834; background-size: 12px auto !important;}
    .form-group-radio.NotQualified .form-radio:checked+label:before{background-color: #d81919; background-size: 12px auto !important;}

    .form-group-radio.Cold .form-radio:checked+label:before{background: url('~@/assets/images/icons/check.svg') no-repeat rgba(31,53,97,0.7); background-size: 12px auto !important;}
    .form-group-radio.Warm .form-radio:checked+label:before{background: url('~@/assets/images/icons/check.svg') no-repeat rgba(240,161,34,0.7); background-size: 12px auto !important;}
    .form-group-radio.Hot .form-radio:checked+label:before{background: url('~@/assets/images/icons/check.svg') no-repeat #ea4300; background-size: 12px auto !important;}

    .item-buttons{display: flex; justify-content: center; align-items: center; margin: 45px 0;}
    .item-buttons .recruit{margin-right: 30px;}
    .item-buttons .recruit .btn-recruit{padding: 7px 20px 7px 42px; background: url('~@/assets/images/icons/recruit.svg') no-repeat 10px center var(--color-tertiary); background-size: auto 22px; border-radius: 8px; cursor: pointer;}
    .item-buttons .btn-primary{margin-top: 0;}

    .success{position: relative; background: var(--color-secondary); border-radius: 12px; padding: 41px 16px 30px 16px; margin: 60px 0 20px; position: relative; z-index: 10; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);}
    .success:before{content: ''; display: block; position: absolute; left: 50%; top: -30px; transform: translateX(-50%); width: 50px; height: 50px; background: url('~@/assets/images/icons/step-status-validated.svg') no-repeat center var(--color-text-tertiary); background-size: 50% auto; border-radius: 50%; border: 5px solid var(--color-secondary);}
    .success h2{margin: 0; text-align: center; color: var(--color-text-tertiary);}


    .modal-recruit .load-blue{margin: 50px auto;}
    .modal-recruit .btn-primary{margin: 0 0 15px 0;}
    .modal-recruit label{color: var(--color-text-tertiary);}

    .success-recruit{display: flex; flex-direction: column; align-content: center; width: 100%; max-width: 500px; margin: 10px auto;}
    .success-recruit .resume-recruit{background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; text-align: left; border-radius: 12px 12px 0 0; padding: 20px;}
    .success-recruit .resume-recruit p{color: var(--color-text-tertiary); margin-bottom: 5px;}
    .success-recruit .resume-recruit p:last-child{margin-bottom: 0;}
    .success-recruit .instruction-recruit{margin: 0 auto 20px; width: 100%; max-width: 360px;}
    .success-recruit .success-share{padding: 16px; border-radius: 0 0 12px 12px; background: url('~@/assets/images/bg-blue.png') no-repeat; background-size: cover;}
    .success-recruit .success-share p{color: var(--color-text-tertiary); text-align: center; margin-bottom: 20px;}
    .success-recruit .success-share .share{background-color: var(--color-text-tertiary); margin: 0;}
    .success-recruit .success-share .share span{color: var(--color-text-secondary);}

    .share-has-invite{margin-top: 60px; padding: 16px; border-radius: 12px; background: rgba(255,255,255,0.2);}
    .share-has-invite .has-invite-msg{color: var(--color-text-tertiary); text-align: center; margin-bottom: 10px;}
    .share-has-invite label .share{margin: 20px 0 0 0;}

    .recruitment-progress{font-size: 20px; color: var(--color-secondary);}


    @media (max-width: 576px){
        .twoColumns{display: block;}
        .twoColumns label{width: 100%;}
        .twoColumns label:nth-of-type(1){padding-right: 0;}
        .twoColumns label:nth-of-type(2){padding-left: 0;}
        .twoColumns label input[type="date"]{-webkit-appearance: none;}

        .item-buttons{flex-direction: column;}
        .item-buttons .recruit{order: 2; margin: 30px 0 0 0;}
        .item-buttons .btn-primary{order: 1;}

        .share{margin-bottom: 30px;}
    }
</style>