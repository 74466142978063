<template>
    <div>
        <ion-header class="header" v-if="!load">
            <ion-row class="header-row">
                <div class="logo-col">
                    <div class="logo-dne" @click="lnkDash()">
                        <img src="@/assets/images/dne.svg">
                    </div>
                </div>

                <div class="welcome-col">
                    <div class="welcome-seller">
                        <div class="welcome">
                            <h4>{{dataAgent.name}}</h4>
                            <p>{{ getMyLanguage("status", dataAgent.agentType) }}</p>
                        </div>
                    </div>
                </div>

                <div class="menu-col">
                    <SelectLanguage />

                    <div class="open-menu" v-if="menu === false" @click="menu = true">
                        <img src="~@/assets/images/icons/menu.svg">
                    </div>

                    <div class="close-menu" v-if="menu === true" @click="menu = false">
                        <img src="~@/assets/images/icons/menu-close.svg">
                    </div>

                    <div class="menu" v-if="menu === true">
                        <div @click="lnkDash()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-dash.svg"></div>
                            <span>{{ getMyLanguage("menu", "dashboard") }}</span>    
                        </div>

                        <div @click="lnkClients()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-clients.svg"></div>
                            <span>{{ getMyLanguage("menu", "clients") }}</span>
                        </div>

                        <div @click="openInvite()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-new-lead.svg"></div>
                            <span>{{ getMyLanguage("menu", "new-invite") }}</span>
                        </div>

                        <div @click="lnkSales()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-sales.svg"></div>
                            <span>{{ getMyLanguage("menu", "sales") }}</span>
                        </div>
                        
                        <div @click="lnkSalesForce()" class="lnk" v-if='dataAgent.agentTypeCode === "regional_director" || dataAgent.agentTypeCode === "master_franchising"'>
                            <div class="icon"><img src="~@/assets/images/menu/lnk-sales-force.svg"></div>
                            <span>{{ getMyLanguage("menu", "sales-force") }}</span>
                        </div>

                        <div @click="lnkAgents()" class="lnk" v-if='dataAgent.agentTypeCode === "regional_director" || dataAgent.agentTypeCode === "master_franchising"'>
                            <div class="icon"><img src="~@/assets/images/menu/lnk-settings.svg"></div>
                            <span>{{ getMyLanguage("menu", "agents") }}</span>
                        </div>
                        
                        <div @click="lnkInvites()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-settings.svg"></div>
                            <span>{{ getMyLanguage("menu", "invites") }}</span>
                        </div>

                        <div @click="lnkProfile()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-profile.svg"></div>
                            <span>{{ getMyLanguage("menu", "profile") }}</span>
                        </div>
                        
                        <div @click="lnkManuals()" class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-manuals.svg"></div>
                            <span>{{ getMyLanguage("menu", "manuals") }}</span>
                        </div>
                        
                        <div @click="lnkReport()" class="lnk" v-if='dataAgent.agentTypeCode === "regional_director" || dataAgent.agentTypeCode === "master_franchising"'>
                            <div class="icon"><img src="~@/assets/images/menu/lnk-report.svg"></div>
                            <span>{{ getMyLanguage("menu", "report") }}</span>
                        </div>
                        
                        <!-- <div class="lnk">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-settings.svg"></div>
                            <span>{{ getMyLanguage("menu", "settings") }}</span>
                        </div> -->

                        <div @click="logoutPlatform()" class="lnk lnk-logout">
                            <div class="icon"><img src="~@/assets/images/menu/lnk-logout.svg"></div>
                            <span>{{ getMyLanguage("menu", "logout") }}</span>
                        </div>
                    </div>

                </div>
            </ion-row>
            
            <div class="overlaymenu" v-if="menu === true" @click="menu = false"></div>
        </ion-header>


        <!-- MODAL INVITE -->
        <div class="modal" v-if="modalInvite.modal">
            <div @click='(modalInvite.modal = false),(typeInvite = "")' class="close-modal"></div>
            <div class="box cont-modal">
                <h3 class="title-secondary">{{ getMyLanguage("menu", "modalInvite.title") }}</h3>

                <div class="load-blue" v-if="!showContent"></div>

                <!-- HAS INVITE -->
                <div class="box has-invite" v-if="showContent">
                <!-- <div class="box has-invite"> -->
                    <label>
                        Identificação do convite
                        <input type="text" class="form" v-model="description" placeholder="Ex.: nome do cliente">
                    </label>
                        
                    <div class="bts-invite" v-if="description.length < 5">
                        <div class="btn btn-disable">{{getMyLanguage("menu","modalInvite.generate.customer")}}</div>
                        <div class="btn btn-disable">{{getMyLanguage("menu","modalInvite.generate.company")}}</div>
                    </div>

                    <div class="bts-invite" v-if="description.length >= 5">
                        <div class="btn" :class='{active: typeInvite === "account_invite"}' @click='(typeInvite = "account_invite"),(modalInvite.invite),(newInvite(typeInvite))'>{{getMyLanguage("menu","modalInvite.generate.customer")}}</div>
                        <div class="btn" :class='{active: typeInvite === "company_invite"}' @click='(typeInvite = "company_invite"),(modalInvite.invite),(newInvite(typeInvite))'>{{getMyLanguage("menu","modalInvite.generate.company")}}</div>
                    </div>

                    <div v-if="showContent && dataInvite.hasInvite">
                        <div class="qtd-invite">
                            <div>{{ getMyLanguage("menu", "modalInvite.used") }}: {{dataInvite.used}}</div>
                            <div>{{ getMyLanguage("menu", "modalInvite.remaining") }}: {{dataInvite.remaining}}</div>
                        </div>
                        
                        <!-- <div class="lnk-invite">
                            {{dataInvite.link}}
                        </div> -->

                        <label>
                            {{ getMyLanguage("register", "register.label.share") }}
                            <div class="share" @click="copyShare(dataInvite.link)"><span>{{dataInvite.link}}</span></div>
                        </label>
                    </div>

                    <!-- <div class="box uninvited" v-if="showContent && !dataInvite.hasInvite">
                        <h2>{{ getMyLanguage("menu", "modalInvite.uninvited.alert") }}</h2>
                        <p>{{ getMyLanguage("menu", "modalInvite.uninvited.text") }}</p>
                    </div> -->
                </div>


                <!-- UNINVITED -->
                <!-- <div class="box uninvited" v-if="showContent && !dataInvite.hasInvite && !modalInvite.invite">
                    <h2>{{ getMyLanguage("menu", "modalInvite.uninvited.alert") }}</h2>
                    <p>{{ getMyLanguage("menu", "modalInvite.uninvited.text") }}</p>
                </div> -->
            </div>
        </div>

        <div class="overlayModal" v-if="modalInvite.modal"></div>
    </div>
</template>

<script>

    console.log(process.env)

    import {IonHeader, IonRow, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import SelectLanguage from '@/components/SelectLanguage.vue'

    import api from '@/config/api.js';

    export default {
        components: {
            IonHeader, IonRow,
            SelectLanguage
        },
        data () {
            return {
                load: true,
                flagSelected: {},
                dataAgent: [],
                menu: false,

                errorsGet: {
                    dataAgent: false
                },

                showContent: false,
                dataInvite: [],
                modalInvite: {
                    modal: false,
                    invite: false,
                },
                
                description: "",
                typeInvite: ""
            }
        },

        methods: {
            flagUrl(){
                this.flagSelected = String(window.location.pathname).split("/")[1];
            },
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = this.flagSelected + '/login/'
                }
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },


            lnkDash(){
                window.location.href = this.flagSelected + '/dashboard/'
            },

            lnkProfile(){
                window.location.href = this.flagSelected + '/seller/profile/'
            },
            
            lnkManuals(){
                window.location.href = this.flagSelected + '/seller/manuals/'
            },
            
            lnkReport(){
                window.location.href = this.flagSelected + '/seller/report/'
            },

            lnkClients(){
                window.location.href = this.flagSelected + '/leads/?name=' + this.dataAgent.name
            },

            lnkSales(){
                window.location.href = this.flagSelected + '/sales/'
            },

            lnkSalesForce(){
                window.location.href = this.flagSelected + '/sales/sales-force/'
            },
            
            lnkAgents(){
                window.location.href = this.flagSelected + '/leads/agents/'
            },
            
            lnkInvites(){
                window.location.href = this.flagSelected + '/invites/'
            },

            lnkNewLead(){
                window.location.href = this.flagSelected + '/leads/check/'
            },

            lnkSettings(){
                window.location.href = this.flagSelected + '/dashboard'
                // window.location.href = this.flagSelected + '/seller/settings'
            },

            logoutPlatform(){
                localStorage.removeItem('accessToken')
                window.location.href = this.flagSelected + '/login/'
            },

            openInvite(){
                this.modalInvite.modal = true
                this.showContent = true
                this.description = ""
                this.dataInvite.hasInvite = false
            },

            async newInvite(){
                this.showContent = false
                this.menu = false

                const resumeInvite = await api.get('/api/v1/platform/account/invite/' + this.typeInvite + '?description=' + this.description)
                this.dataInvite = resumeInvite.data

                this.showContent = true
            },

            async copyShare(urlShareInvite) {
                try {
                await navigator.clipboard.writeText(urlShareInvite);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("leads", "message-toast.copied"),
                        color: "secondary",
                        duration: 5000,
                    })
                return toast.present();
            },            
            
            async openToastNocopy() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("leads", "message-toast.noCopied"),
                        color: "primary",
                        duration: 5000
                    })
                return toast.present();
            },
        },

        async mounted () {
            this.flagUrl();
            
            await api.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataAgent = response.data
            })
            .catch(error => {
                // this.errorsGet.dataAgent = JSON.stringify(error.response.status)
                
                if (error.response.status == 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
                
                if (error.response.status == 409) {
                    window.location.href = '/' + this.flagSelected + '/dashboard/agent-sign'
                }
                
                if (error.response.status == 403) {
                    window.location.href = '/' + this.flagSelected + '/page403'
                }

                // else{
                //     window.location.href = '/' + this.flagSelected + '/page403'
                // }
            })

            this.load = false
            
            
            // const resumeInvite = await api.get('/api/v1/platform/account/invite')
            // .then(function (){
            //     this.dataInvite.hasInvite = true

            //     this.dataInvite = resumeInvite.data
            //     // console.log(response.data);
            // })
            // .catch(error => {
            //     this.dataInvite.hasInvite = false

            //     // console.log(JSON.stringify(error))
            //     const errorsInvite = JSON.stringify(error.response.status)
            //     this.loginErrors = errorsInvite
            //     // console.log(errorsInvite)
            
            //     // if ( errorsInvite === 401 || errorsInvite === 403 ){
            //     //     console.log(errorsInvite)
            //     // }
            // })
                
        }
    }
</script>


<style scoped>
    .header-row{background: var(--color-text-tertiary); border-radius: 0 0 12px 12px; padding: 8px; position: relative; z-index: 30; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); align-items: center;}
    .header-md:after{display: none;}

    .logo-col{width: 70px}
    .welcome-col{width: calc(100% - 160px);}
    .menu-col{width: 90px}

    .logo-dne{width: 100%; height: 55px; display: flex; justify-content: center; align-items: center;}
    .logo-dne img{width: auto; height: 40px; display: block;}

    .welcome-seller .welcome p{color: #868788; font-size: 12px; line-height: 14px; margin: 0;}
    .welcome-seller .welcome h4{font-family: var(--font-primary-semibold); font-size: 16px; font-weight: 600; color: var(--color-primary); margin: 0 0 5px 0;}

    .menu-col{position: relative; display: flex; justify-content: space-between; align-items: center;}
    .menu-col .open-menu, .menu-col .close-menu{width: 40px; height: 40px; padding: 8px; cursor: pointer;}

    .menu{position: absolute; z-index: 25; top: 130%; right: 6px; padding: 16px 10px; border-radius: 12px; background: var(--color-text-tertiary); -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -webkit-animation: showMenu .4s alternate;}
    .menu .lnk{display: flex; align-items: center; margin-bottom: 8px; padding: 0 10px; cursor: pointer;}
    .menu .lnk .icon{width: 30px; height: 30px; margin-right: 10px; display: flex; justify-content: center; align-items: center; border: 2px solid rgba(31,53,94,.2); border-radius: 50%;}
    .menu .lnk .icon img{width: 16px; height: 16px;}
    .menu .lnk span{font-size: 13px; color: var(--color-text-secondary); line-height: 30px; white-space: nowrap;}
    
    .menu .lnk.lnk-logout{margin: 20px 0 0 0;}
    .menu .lnk.lnk-logout span{color: var(--color-cancel);}

    .overlaymenu{position: absolute; z-index: 20; top: 0; left: 50%; transform: translateX(-50%); width: 200%; height: 100vh; background: rgba(0,0,0,.7); -webkit-animation: showMenuOverlay .2s alternate;}

    @keyframes showMenu{
        0% {margin-top: 20px;}
        100% {margin-top: 0;}
    }

    @keyframes showMenuOverlay{
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
</style>