<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <Header />

            <ion-content>
                <div class="content ion-padding">
                    <h3 class="title-secondary" v-if="!filter">
                        {{ getMyLanguage("invites", "invites.title") }}
                        <small v-if="nameLeadFilter">{{nameLeadFilter}}</small>
                    </h3>

                    <div class="load-green" v-if="loading"><span></span></div>

                    <div class="search-bar" v-if="!loading && dataInvites.length && !errorsGet.dataInvites && !filter">
                        <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("leads", "leads.search")'>
                        <div class="btn-filter" @click="filter = true"></div>
                        <div class="btn-back" v-if="nameLeadFilter" onclick="history.back()"></div>
                    </div>

                    
                    <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>

                    <!-- BOX ERROR -->
                    <div class="box-error" v-if="errorsGet.dataInvites != false">
                        <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                        <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataInvites) }}</p> -->
                        <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsGet.dataInvites}}
                        </div>
                    </div>


                    <div class="filter box" v-if="filter">
                        <div class="close-filter" @click="filter = false"></div>

                        <div class="filters">
                            <div class="form-group-switch">
                                <input type="checkbox" v-model="acceptWithdraw" name="" id="acceptWithdraw" class="form-switch">
                                <label for="acceptWithdraw" class="form-switch-label">
                                    <small>Filter name</small>
                                </label>
                            </div>
                        </div>

                        <div class="btn-primary">Filter</div>
                    </div>

                    <div class="box" v-if="!loading && dataInvites.length && !errorsGet.dataInvites">
                        <div class="scroll-horizontal">
                            <table class="list-result" id="drop-list">
                                <thead class="header-list">
                                    <tr>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","invited-by") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","invitation-to") }}</th>
                                        <th class="date">{{ getMyLanguage("list-result","createdAt") }}</th>
                                        <th class="date">{{ getMyLanguage("list-result","expiresAt") }}</th>
                                        <th class="type">{{ getMyLanguage("list-result","type") }}</th>
                                        <!-- <th class="delivery">{{ getMyLanguage("list-result","delivery") }}</th> -->
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="leads"></th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(listInvite,index) in dataInvites" :key="index">
                                        <td class="fullName text-left"><span>{{listInvite.agent_name}}</span></td>
                                        <td class="fullName text-left">
                                            <span v-if='listInvite.invite_status != "Generated"'>{{listInvite.account_full_name}}</span>
                                            <span v-if='listInvite.invite_status === "Generated"' class="pending">{{listInvite.invite_description}}</span>
                                            <!-- <span v-if='listInvite.invite_type_code === "agent_invite" && listInvite.invite_status === "Generated"' class="pending">{{listInvite.account_full_name}}</span> -->
                                        </td>
                                        <td class="date"><span>{{dateTime(listInvite.invite_created_at)}}</span></td>
                                        <td class="date"><span>{{dateTime(listInvite.invite_expires_at)}}</span></td>
                                        <td class="type"><span>{{ getMyLanguage("status", listInvite.invite_type_code) }}</span></td>
                                        <!-- <td class="delivery" :class="listInvite.delivery_status">
                                            <div class="status-delivery"></div>
                                            <div class="tooltip-delivery">{{listInvite.delivery_status_reason}}</div>
                                        </td> -->
                                        <td class="status" :class="listInvite.invite_status"><span>{{ getMyLanguage("status", listInvite.invite_status) }}</span></td>
                                        <td class="leads">
                                            <span @click="listInvites(listInvite.account_id, listInvite.account_full_name)" v-if="listInvite.is_agent">{{ getMyLanguage("menu", "invites") }}</span>
                                            <small v-if="!listInvite.is_agent">- -</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="no-results" v-if="!loading && !dataInvites.length">
                        <p>{{ getMyLanguage("invites", "no-invite") }}</p>
                        <div class="btn-back" v-if="nameLeadFilter" onclick="history.back()"></div>
                    </div>
                </div>
            </ion-content>


            <!-- MODAL INVITE -->
            <div class="modal modal-invite" v-if="showModal">
                <div @click="this.showModal = false" class="close-modal"></div>
                <div class="box cont-modal">
                    <div class="scroll-vertical">
                        <h3 class="title-secondary">
                            <span v-if='dataModal.account_full_name != ""'>{{dataModal.account_full_name}}</span>
                            <span v-if='dataModal.account_full_name === ""'>- -</span>
                            <small>{{dataModal.agent_name}}</small>
                        </h3>
                       
                       <div class="box resume" :class="dataModal.invite_status">
                            <p><strong>{{ getMyLanguage("list-result","createdAt") }}: </strong>{{dateTimeYear(dataModal.invite_created_at)}}</p>
                            <p><strong>{{ getMyLanguage("list-result","expiresAt") }}: </strong>{{dateTimeYear(dataModal.invite_expires_at)}}</p>
                            <p><strong>{{ getMyLanguage("list-result","type") }}: </strong>{{ getMyLanguage("status", dataModal.invite_type_code) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status", dataModal.invite_status) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","origin") }}: </strong>{{ getMyLanguage("status", dataModal.origin) }}</p>
                            <p v-if="dataModal.account_profile_type_code != null"><strong>{{ getMyLanguage("list-result","profile_type") }}: </strong>{{ getMyLanguage("status", dataModal.account_profile_type_code) }}</p>
                        </div>

                        {{dataModal}}
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="showModal"></div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import api from '@/config/api.js'
    import moment from 'moment';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                loading: true,
                dataInvites: [],

                lengthLeads: false,

                nameLeadFilter: false,

                errorsGet: {
                    dataInvites: false
                },
                
                filter: false,
                filtersParam: {},
                
                dataModal: [],
                showModal: false,
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).format('DD/MM');
            },
            dateTimeYear(value) {
                return moment(value).format('DD/MM/YYYY');
            },
            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            
            viewInvite(value){
                this.dataModal = this.dataInvites[value]
                this.showModal = true
            },

            listInvites(value, name){
                window.location.href = '/' + this.flagSelected + '/invites?account_id=' + value + "&name=" + name
            }
        },


        async mounted () {
            this.flagUrl();

            const urlParams = new URLSearchParams(window.location.search);
            const accountIdParams = urlParams.get('account_id');
            const nameLead = urlParams.get('name')
            this.nameLeadFilter = nameLead

            if ( accountIdParams == null || accountIdParams == '' ){
                api.get('/api/v1/agent/invites')
                .then(response => {
                    this.dataInvites = response.data

                    if ( this.dataInvites.length > 0 ){
                        this.lengthInvites = true
                    } else{
                        this.lengthInvites = false
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errorsGet.dataInvites = error.response.status
                })
            } else{
                api.get('/api/v1/agent/invites?account_id=' + accountIdParams)
                .then(response => {
                    this.dataInvites = response.data

                    if ( this.dataInvites.length > 0 ){
                        this.lengthInvites = true
                    } else{
                        this.lengthInvites = false
                    }
                    
                    this.loading = false
                })
                .catch(error => {
                    this.errorsGet.dataInvites = error.response.status
                })
            }
            
            
            // await api.get('/api/v1/agent/invites')
            // .then(response => {
            //     this.dataInvites = response.data
            // })
            // .catch(error => {
            //     this.errorsGet.dataInvites = error.response.status

            //     if ( this.errorsGet.dataInvites == 401 ){
            //         localStorage.removeItem('accessToken');
            //         this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
            //     } else if ( this.errorsGet.dataInvites == 403 ){
            //         this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
            //     }
            // })
        }

    }
</script>


<style scoped>
    .list-result{border-spacing: 0; border-collapse: separate;}
    .list-result .list{cursor: default;}
    .list-result .list span{white-space: nowrap;}
    .list-result .date{text-align: center;}
    /* .list-result tbody tr{cursor: pointer;} */
    .list-result tr.list td:first-child{border-radius: 10px 0 0 12px;}
    .list-result tr.list td:last-child{border-radius: 0 10px 12px 0;}
    .list-result .fullName{width: 50%;}
    .list-result .fullName:first-child{padding-left: 10px;}
    .list-result .fullName span.pending{color: var(--color-pending);}

    .delivery{position: relative;}
    .delivery .status-delivery{ z-index: 20; width: 40px; height: 20px; background-size: auto 100% !important; margin: 0 auto;}
    .delivery:hover .tooltip-delivery{display: block;}
    .delivery .tooltip-delivery{-webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5); -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5); box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5); display: none; position: absolute; z-index: 50; bottom: 27px; left: 50%; transform: translateX(-50%); height: 30px; line-height: 30px; padding: 0 10px; border-radius: 6px; background: #ccc;}
    .delivery .tooltip-delivery:after{content: ''; display: block; position: absolute; z-index: -1; left: 50%; bottom: -7px; transform: translateX(-50%); width: 0; height: 0; border-left: 7px solid transparent; border-right: 7px solid transparent; border-top: 7px solid #CCC;}
    .delivery.Received .status-delivery{background: url('~@/assets/images/icons/delivery-received.svg') no-repeat center;}
    .delivery.Received .tooltip-delivery{background: var(--color-secondary); color: var(--color-text-tertiary); white-space: nowrap;}
    .delivery.Received .tooltip-delivery:after{border-top-color: var(--color-secondary);}


    /* MODAL */
    .modal{width: 800px; max-width: 80%;}
    .scroll-vertical{max-height: 70vh;}
</style>